import React, { useState, useContext } from "react";
import { QueryContext } from "../context/QueryContext";
import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";

import Box from "@mui/material/Box";
// import Slider from "@mui/material/Slider";

import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  menuListContainer: {
    // background: "#4d4dff",
    //float: 'left',
    // marginLeft: "5px",
    // marginRight: "5px",
    // marginBottom: "10px",
    marginTop: "0px",
    paddingTop: "0px",
  },
  inactiveLayer: {
    // textDecoration: "line-through",
    // marginLeft: 20,
    // color: "#D4D4D4",
    color: "#C0C0C0",
  },
  activeLayer: {
    // textDecoration: "line-through",
    // marginLeft: 20,
    color: "inherit",
  },
}));

export default function DataParameters(props) {
  const { regionSelected, setRegionSelected, zoomLevel } = props;
  const classes = useStyles();
  const [query, dispatch] = useContext(QueryContext);

  return (
    <Paper
      sx={{
        // position: "fixed",
        position: "Absolute",
        // bottom: "0.01%",
        // left: 0,
        // right: "34%",
        right: 0,
        alignItems: "center",
        justifyContent: "left",
        width: 210,
      }}
    >
      <Accordion
        // sx={{ width: "100%" }}
        // sx={{ width: 225, right: "0%" }}
        disableGutters={true}
        defaultExpanded={true}
      >
        <Tooltip title="Click to hide or expand Interactive Legend.">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ marginBottom: 0, paddingBottom: 0 }}
          >
            <Typography
              variant="h6"
              noWrap
              sx={{ fontSize: 18, fontStyle: "bold" }}
            >
              Map Features
            </Typography>
          </AccordionSummary>
        </Tooltip>
        <AccordionDetails sx={{ marginTop: 0, paddingTop: 0 }}>
          <Box>
            {/* <Typography sx={{ fontSize: 8 }}>
            Wide zoom columns are clusters of parking events. Zoom in for
            parking events.
          </Typography> */}
            <Typography sx={{ fontSize: 14 }}>
              Clusters of parking events.
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
              Parking Duration:
            </Typography>

            <Typography sx={{ fontSize: 14, ml: 1 }}>
              Less than 1 hour
            </Typography>

            <Typography sx={{ fontSize: 14, ml: 1 }}>1 to 3 hours</Typography>

            <Typography sx={{ fontSize: 14, ml: 1 }}>3 to 7 hours</Typography>

            <Typography sx={{ fontSize: 14, ml: 1 }}>7 to 11 hours</Typography>

            <Typography sx={{ fontSize: 14, ml: 1 }}>
              Greater than 11 hours
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              <strong>
                Grid Resolution:{" "}
              </strong>
              {query.stateMapGridResolution}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
