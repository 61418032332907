/** see https://upmostly.com/tutorials/how-to-use-the-usecontext-hook-in-react*/
import React, { createContext, useReducer } from "react";
import {
  yearsMonths,
  geographies,
  sitesYearsMonths,
} from "../common/constants";

const initialState = {
  popUpEnabled: true,
  highlightQuery: function (feature) {
    return false;
  },
  regionParkingData: [],
  // regionSelected: {
  //   selected: "Statewide",
  //   year: 2018,
  //   month: 1,
  //   yearMonthOptionsIndex: 0,
  //   geographyOptionsIndex: 1,
  // },
  regionParkingDataType: 0,
  regionCategoryTableData: {},
  selectedDayType: "All Daytypes",
  selectedVehicleWeightClass: 0,
  selectedSite: {
    selected: "El Paso County",
    year: sitesYearsMonths[sitesYearsMonths.length - 1].year,
    month: sitesYearsMonths[sitesYearsMonths.length - 1].month,
    // yearMonth: sitesYearsMonths[0].yearMonth,
    yearMonth: sitesYearsMonths.filter(
      (itm) => itm.year === sitesYearsMonths[sitesYearsMonths.length - 1].year
    )[0].yearMonth,
    parkingDataType: 0,
    parkingDurationCategories: [1, 2, 3, 4, 5],
  },
  statewide: {
    year: yearsMonths[yearsMonths.length - 1].year,
    month: yearsMonths[yearsMonths.length - 1].month,
    yearMonth: yearsMonths.filter(
      (itm) => itm.year === yearsMonths[yearsMonths.length - 1].year
    )[0].yearMonth,
  },
  selectedComponents: {
    Nday: true,
    Nhour: true,
    Sday: true,
    Shour: true,
    Eday: true,
    Ehour: true,
    Wday: true,
    Whour: true,
    utilizationByDay: true,
    utilizationByHour: true,
  },
  stateMapGridResolution: 6,
  mapOpacity: 0.8,
  cardAttributes: {
    usBorder: "active",
    disruptiveEvents: "inactive",
    freightGenerators: "inactive",
  },
};

const QueryContext = createContext(null);

const queryReducer = (state, action) => {
  switch (action.type) {
    case "setRegionParkingData":
      //regionParkingData
      return {
        ...state,
        regionParkingData: action.selection,
      };
    //   regionSelected: "Statewide",
    //   regionYearSelected: 2018,
    //   regionMonthSelected: 1,
    // case "setRegionSelected":
    //   return {
    //     ...state,
    //     regionSelected: action.selection,
    //   };
    case "setRegionParkingDataType":
      return {
        ...state,
        regionParkingDataType: action.selection,
      };
    case "setRegionCategoryTableData":
      return {
        ...state,
        regionCategoryTableData: action.selection,
      };
    case "setSelectedVehicleWeightClass":
      return {
        ...state,
        selectedVehicleWeightClass: action.selection,
      };
    case "setSelectedDayType":
      return {
        ...state,
        selectedDayType: action.selection,
      };
    case "setSelectedSite":
      return {
        ...state,
        selectedSite: action.selection,
      };
      case "statewide":
      return {
        ...state,
        statewide: action.selection,
      };
    case "setToggle":
      return {
        ...state,
        selectedComponents: action.selection,
      };
    case "setStateMapGridResolution":
      return {
        ...state,
        stateMapGridResolution: action.selection,
      };
    case "setMapOpacity":
      return {
        ...state,
        mapOpacity: action.selection,
      };
    case "setCardAttributes":
      return {
        ...state,
        cardAttributes: action.selection,
      };
    default:
      throw new Error("error in query");
  }
};

const QueryProvider = (props) => {
  const [state, dispatch] = useReducer(queryReducer, initialState);
  return (
    <QueryContext.Provider value={[state, dispatch]}>
      {props.children}
    </QueryContext.Provider>
  );
};

export { QueryContext, QueryProvider };
