import React, { useState } from "react";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { IconButton } from "@mui/material";

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <IconButton
      onClick={scrollTop}
      style={{ height: 5, display: showScroll ? "flex" : "none" }}
    >
      <ArrowCircleUpIcon className="scrollTop" style={{ color: "white" }} />
    </IconButton>
  );
};

export default ScrollArrow;
