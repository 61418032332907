/**
 * @fileOverview the main UI component
 */

import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useContext,
  // useCallback,
} from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import { Button, TextField, Autocomplete } from "@mui/material";

import Typography from "@material-ui/core/Typography";

import "../App.css";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeTheme, sidebarStyles, gridStyles } from "../ui/styles";
import "../App.scss";
import Grid from "@material-ui/core/Grid";
import DeckglMapSites from "../map/DeckglMapSites.js";

import { QueryProvider, QueryContext } from "../context/QueryContext";

import {
  fetchParkingSitesGeom,
  findParkingSiteEvents,
  findSiteUnoffRatioByDirYearMonthDay,
  findSiteOffUnoffByYearMonthDay,
  findSiteOffUnoffByYearMonthHour,
  fetchExpansionFactorsSiteYearMonthy,
} from "../data-loaders/DataLoaders";
// import axios from "axios";
import { txdotDistricts, parkingSites, mpoList } from "../common/constants";

import ChartsLayoutSites from "../Charts/ChartsLayoutSites";
import ChartsLayoutPrivateSites from "../Charts/ChartsLayoutPrivateSites";
import TablePanelSites from "../table/TablePanelSites";
import Dashboard from "./DashboardOverview";

import { useNavigate, useSearchParams } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";

import OptionsBar from "../ui/OptionsBar";

function SitesLayout(props) {
  const {statusGuide} = props;

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classesGrid = gridStyles();

  const [query, dispatch] = useContext(QueryContext);
  //console.log("query.selectedSite-check", query.selectedSite);

  const [parkingSiteGeom, setParkingSiteGeom] = useState(null);
  const [siteEventsGeom, setSiteEventsGeom] = useState(null);
 
  const [siteOffUnoffByYearMonthHourData, setSiteOffUnoffByYearMonthHourData] =
    useState([{ sitename: "None" }]);
  const [siteOffUnoffByYearMonthDayData, setSiteOffUnoffByYearMonthDayData] =
    useState([{ sitename: "None" }]);

  const [unauthorizedPercentTable, setUnauthorizedPercentTable] =
    useState(null);
  const [spaceRatioData, setSpaceRatioData] = useState(null);
  const [unofficialDataDay, setUnofficialDataDay] = useState(null);
  const [expansionFactor, setExpansionFactor] = useState(0.1);

  let publicParkingSites = parkingSites.filter((item) => item.type === 'Public')
  /** Add siteName to the URL to allow bookmarking and to reference in other places*/
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const siteNameFromUrl = searchParams.get('siteName');
  let siteData = publicParkingSites.filter(item => item.name === siteNameFromUrl);
  
  /*Filter and select option variables*/
  const [selectedAreaGroupFilter, setSelectedAreaGroupFilter] = useState('District');
  const [selectedAreaGroupFilterData, setSelectedAreaGroupFilterData] = useState(txdotDistricts);
  const filterArea = selectedAreaGroupFilterData.filter(item => item.districtName === siteData[0].districtName);
  const [selectedArea, setSelectedArea] = useState(filterArea[0]);
  const filteredSite = selectedArea ? publicParkingSites.filter(item => item.districtName === selectedArea.districtName ) : publicParkingSites;
  const [selectedSiteTypeData, setSelectedSiteTypeData] = useState(filteredSite);
  const [selectedSite, setSelectedSite] = useState(siteData[0]);

  /**Styles for sites filter */
  const actionStyle = {
    flex: 2,
    alignItems:'center',
    marginLeft:'60px',
    marginBottom:'10px'
  };

  const wrapperStyle = {
    display: "flex",
    margin:'6px',
    alignItems: 'center',
  };

  const buttonStyle = {
    minWidth:'100px',
    marginRight:'2px'
  }

  const labelStyle = {
    flex: 0.45,
    color:'black'
  };

  useEffect(() => {
    if(selectedSite.name){
      searchParams.set('siteName', selectedSite.name);
      navigate({ search: searchParams.toString() });
      dispatch({
        type: "setSelectedSite",
        selection: {
          ...query.selectedSite,
          selected: selectedSite.name
        },
      });
    }
  }, [selectedSite]);

  useEffect(() => {
    if(selectedSite && selectedSite.name){
      dispatch({
        type: "setToggle",
        selection: {
          Nday: true,
          Nhour: true,
          Sday: true,
          Shour: true,
          Eday: true,
          Ehour: true,
          Wday: true,
          Whour: true,
          utilizationByDay: true,
          utilizationByHour: true,
        },
      });
      let site = selectedSite.name;
      // console.log("query-check", query.selectedComponents);
      async function asyncCall() {
        // console.log("siteSelected-check", siteSelected.year);
        await findSiteOffUnoffByYearMonthHour(
          site,
          // ste,
          query.selectedSite.year
        ).then((response) => {
          // console.log(
          //   "response-off-unoff-yr-hour-layout-LoadData-check",
          //   response,
          //   siteOffUnoffByYearMonthHourData[0],
          //   site
          // );
          if (response.data && response.data.length > 0)
            // dispatch({
            //   type: "setSiteOffUnoffByYearMonthHourData",
            //   selection: response.data,
            // });
            setSiteOffUnoffByYearMonthHourData(response.data);
        });

        await findSiteOffUnoffByYearMonthDay(
          site,
          // ste,
          query.selectedSite.year
        ).then((response) => {
          // console.log(
          //   "response-findSiteOffUnoffByYearMonthDay-check",
          //   response
          //   // siteOffUnoffByYearMonthHourData[0],
          //   // site
          // );
          if (response.data && response.data.length > 0)
            // dispatch({
            //   type: "setSiteOffUnoffByYearMonthHourData",
            //   selection: response.data,
            // });
            setSiteOffUnoffByYearMonthDayData(response.data);
        });

        await fetchExpansionFactorsSiteYearMonthy(
          site,
          // ste,
          query.selectedSite.year,
          query.selectedSite.month
        ).then((response) => {
          // console.log(
          //   "response-findSiteOffUnoffByYearMonthDay-check",
          //   response
          //   // siteOffUnoffByYearMonthHourData[0],
          //   // site
          // );
          if (response.data && response.data.length > 0)
            // dispatch({
            //   type: "setSiteOffUnoffByYearMonthHourData",
            //   selection: response.data,
            // });
            setExpansionFactor(response.data[0].expansion_fact);
        });

        // await fetchParkingSitesGeom(site).then((response) => {
        //   // console.log("response-check", response);
        //   setParkingSiteGeom({
        //     type: "FeatureCollection",
        //     features: response.data,
        //   });
        // });

        // await findParkingSiteEvents(
        //   site,
        //   query.selectedSite.year,
        //   query.selectedSite.month
        // ).then((response) => {
        //   // console.log("response-check", response);

        //   setSiteEventsGeom(response.data);
        // });
      }
      async function asyncCallGeom() {
        await fetchParkingSitesGeom(site).then((response) => {
          // console.log("response-check", response);
          if (response.data && response.data.length > 0) {
            setParkingSiteGeom({
              type: "FeatureCollection",
              features: response.data,
            });
          }
        });

        await findParkingSiteEvents(
          site,
          query.selectedSite.year,
          query.selectedSite.month
        ).then((response) => {
          response.data && response.data.length > 0 && setSiteEventsGeom(response.data);
        });
      }
      asyncCall();
      asyncCallGeom();
    }
  }, [selectedSite.name,
  query.selectedSite.year,
  query.selectedSite.month]);

  // console.log("SitesLayout-call-check");
  let hourlyCountsSummaryVars = [
    "unauth_ratio",
    "unauth1_ratio",
    "unauth1_3_ratio",
    "unauth3_7_ratio",
    "unauth7_11_ratio",
    "unauth11_ratio",
  ];
  useEffect(() => {
    if (parkingSiteGeom && siteEventsGeom) {
      let tempDataTable = [];
      [
        "less than 1 hour",
        "1-3 hours",
        "3-7 hours",
        "7-11 hours",
        "greater than 11 hours",
      ].map((obj) => {
        let dataTemp = calcUnauthorizedPercent(obj, siteEventsGeom);
        // console.log("dataTemp-check", dataTemp);
        tempDataTable.push(dataTemp);
      });
      let totalCount = sumByName(tempDataTable, "totalCount");
      let authorizedCount = sumByName(tempDataTable, "authorizedCount");
      let unauthorizedCount = sumByName(tempDataTable, "unauthorizedCount");
      let percentUnauthorized =
        totalCount > 0 ? (unauthorizedCount / totalCount) * 100 : 0;
      let percentAuthorized = 100 - percentUnauthorized;
      tempDataTable.push({
        siteName: selectedSite.name,
        yearMonth: query.selectedSite.yearMonth,
        parkingCategory: "Total",
        totalCount: totalCount,
        authorizedCount: authorizedCount,
        unauthorizedCount: unauthorizedCount,
        percentUnauthorized: percentUnauthorized,
        percentAuthorized: percentAuthorized,
      });
      setUnauthorizedPercentTable(tempDataTable);

      let tempSpaceRatioData = [];
      let tempUnofficialRatioDataDay = [];
      let siteDirs = selectedSite.name && publicParkingSites.filter(
        (obj) => obj.name === selectedSite.name
      )[0].dir;
      // console.log("siteDirs-check", siteDirs);
      siteDirs && siteDirs.map((ste) => {
        // console.log("ste-check", ste);
        let siteLabel =
          selectedSite.name +
          (ste === "N"
            ? " - Northbound"
            : ste === "S"
            ? " - Southbound"
            : ste === "W"
            ? " - Westbound"
            : ste === "E"
            ? " - Eastbound"
            : "");
        let dailyAveTotalCount = (
          siteEventsGeom.filter(
            (obj) =>
              obj.sitename === selectedSite.name && obj.dir === ste
          ).length / 31
        ).toFixed(0);

        let tempSiteData = [];
        parkingSiteGeom.features.map((fc) => tempSiteData.push(fc.properties));
        // console.log("siteEventsGeom-check", siteEventsGeom);
        // console.log("tempSiteData-check", tempSiteData);
        let spaces = 0;
        if (
          tempSiteData.filter((fc) => fc.dir === ste && fc.authorized === 1)[0]
        )
          spaces = tempSiteData.filter(
            (fc) => fc.dir === ste && fc.authorized === 1
          )[0].space;
        tempSpaceRatioData.push({
          siteName: selectedSite.name,
          dir: ste,
          yearMonth: query.selectedSite.yearMonth,
          siteLabel: siteLabel,
          totalCount: dailyAveTotalCount,
        });

        findSiteUnoffRatioByDirYearMonthDay(
          selectedSite.name,
          ste,
          query.selectedSite.year,
          query.selectedSite.month
        ).then((response) => {
          // console.log(
          //   "findSiteUnoffRatioByDirYearMonthDay-response-check",
          //   response,
          //   siteSelected
          // );
          // setSiteEventsGeom(response.data);

          let daysTemp = [];
          let chartDataTemp = [];
          hourlyCountsSummaryVars.map((item, index) => {
            chartDataTemp.push([]);
          });

          response.data && response.data.length > 0 && response.data.map((obj) => {
            daysTemp.push(obj.start_day);

            hourlyCountsSummaryVars.map((item, index) => {
              chartDataTemp[index].push(obj[item]);
            });
          });

          tempUnofficialRatioDataDay.push({
            siteName: selectedSite.name,
            dir: ste,
            yearMonth: query.selectedSite.yearMonth,
            siteLabel: siteLabel,
            days: daysTemp,
            chartData: chartDataTemp,
          });
        });
      });
      setSpaceRatioData(tempSpaceRatioData);
      setUnofficialDataDay(tempUnofficialRatioDataDay);

      dispatch({
        type: "setToggle",
        selection: {
          Nday: true,
          Nhour: true,
          Sday: true,
          Shour: true,
          Eday: true,
          Ehour: true,
          Wday: true,
          Whour: true,
          utilizationByDay: true,
          utilizationByHour: true,
        },
      });
    }
  }, [parkingSiteGeom,siteEventsGeom]);

  function calcUnauthorizedPercent(parkCat, eventsData) {
    let totalCount = eventsData.filter(
      (obj) => obj.parking_duration === parkCat
    ).length;

    let authorizedCount = eventsData.filter(
      (obj) =>
        obj.parking_duration === parkCat && obj.authorized === "authorized"
    ).length;

    let unauthorizedCount = eventsData.filter(
      (obj) =>
        obj.parking_duration === parkCat && obj.authorized === "unauthorized"
    ).length;

    let percentUnauthorized =
      totalCount > 0 ? (unauthorizedCount / totalCount) * 100 : 0;
    let percentAuthorized = 100 - percentUnauthorized;
    return {
      siteName: selectedSite.name,
      yearMonth: query.selectedSite.yearMonth,
      parkingCategory: parkCat,
      totalCount: totalCount,
      authorizedCount: authorizedCount,
      unauthorizedCount: unauthorizedCount,
      percentUnauthorized: percentUnauthorized,
      percentAuthorized: percentAuthorized,
    };
  }

  const sumByName = (data, name) => {
    return data.reduce(function (a, b) {
      return a + b[name];
    }, 0);
  };

  /* Region boundary geometry in Leaflet's (latitude,longitude) coordinate system */
  const [bounds] = useState([
    [37.99093286, -79.48710834],
    [39.72301501, -75.05239796],
  ]);

  const stageCanvasRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(600);
  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      let height = stageCanvasRef.current.offsetHeight;
      let width = stageCanvasRef.current.offsetWidth;

      setChartHeight(stageCanvasRef.current.offsetHeight);
    }
  }, [stageCanvasRef]);

  let siteType = selectedSite && selectedSite.name && publicParkingSites.filter(
    (itm) => itm.name === selectedSite.name
  )[0].type;

  const [park, setPark] = useState(true);
  function handleToggle(direction, time) {
    let tempSelection = query.selectedComponents;

    if (time === "hour")
      dispatch({
        type: "setToggle",
        selection: {
          ...tempSelection,
          utilizationByHour: !tempSelection.utilizationByHour,
        },
      });
    if (time === "day")
      
      dispatch({
        type: "setToggle",
        selection: {
          ...tempSelection,
          utilizationByDay: !tempSelection.utilizationByDay,
        },
      });

    // if (direction === "N")
    //   if (time === "day")
    //     dispatch({
    //       type: "setToggle",
    //       selection: { ...tempSelection, Nday: !tempSelection.Nday },
    //     });
    //   else
    //     dispatch({
    //       type: "setToggle",
    //       selection: { ...tempSelection, Nhour: !tempSelection.Nhour },
    //     });
    // if (direction === "S")
    //   if (time === "day")
    //     dispatch({
    //       type: "setToggle",
    //       selection: { ...tempSelection, Sday: !tempSelection.Sday },
    //     });
    //   else
    //     dispatch({
    //       type: "setToggle",
    //       selection: { ...tempSelection, Shour: !tempSelection.Shour },
    //     });
    // if (direction === "E")
    //   if (time === "day")
    //     dispatch({
    //       type: "setToggle",
    //       selection: { ...tempSelection, Eday: !tempSelection.Eday },
    //     });
    //   else
    //     dispatch({
    //       type: "setToggle",
    //       selection: { ...tempSelection, Ehour: !tempSelection.Ehour },
    //     });
    // if (direction === "W")
    //   if (time === "day")
    //     dispatch({
    //       type: "setToggle",
    //       selection: { ...tempSelection, Wday: !tempSelection.Wday },
    //     });
    //   else
    //     dispatch({
    //       type: "setToggle",
    //       selection: { ...tempSelection, Whour: !tempSelection.Whour },
    //     });
    // console.log("Query toggle", query.selectedComponents);

    return;
  }

  /* Filtering sites by district/MPO logic */
  
  const getLocationOptionLabel = (option) => {
    if (option) {
      if (option.hasOwnProperty('districtName')){
        return option.districtName.toString();
      }
      else{
        return option.mpoName.toString();
      }
    }
    return '';
  };
  
  const getSiteOptionLabel = (option) => {
    if (option && option.name) {
      return option.name.toString();
    }
    return '';
  };

  const handleAreaGroupFilterClick = (option) => {
      switch (option) {
        case 'District':
          setSelectedArea(null);
          setSelectedAreaGroupFilterData(txdotDistricts);
          setSelectedAreaGroupFilter(option);
          break;
        case 'MPO':
          setSelectedArea(null);
          setSelectedAreaGroupFilterData(mpoList);
          setSelectedAreaGroupFilter(option);
          break;
        // Add more cases for additional buttons if needed
        default:
          setSelectedAreaGroupFilterData([]);
    }
  };

  const handleAreaSelected = async (value) => {
    console.log(value);
    setSelectedArea(value);
    try {
      /** filtered data after a location is clicked */
      let filteredSite = [];
      if (value !== null) {
        if (value.hasOwnProperty('districtName')) {
          filteredSite = publicParkingSites.filter(item => item.districtName === value.districtName)
        } else {
          filteredSite = publicParkingSites.filter(item => item.mpoName === value.mpoName)
        }
        if(filteredSite.length > 0){
          setSelectedSiteTypeData(filteredSite);
          setSelectedSite(filteredSite[0]);
        } else {
          setSelectedSiteTypeData([]);
          setSelectedSite('')
        }
        return;
      } else {
        /** Load all sites if no district or MPO selected */
        setSelectedSiteTypeData(publicParkingSites);
        setSelectedSite(siteData[0]);
      }

    } catch (error) {
      console.error('Error filtering data:', error);
    }
  };

  const handleSiteSelected = (event, value) => {
    if (value) {
      setSelectedSite(value);
    }
  };

  return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={7}>
            <Grid item xs={12}>
              <Box id="map" className={mode} >
                {selectedSite.name && parkingSiteGeom && siteEventsGeom &&
                  <DeckglMapSites
                    parkingSiteGeom={parkingSiteGeom}
                    siteEventsGeom={siteEventsGeom}
                    siteSelected={selectedSite.name}
                  />}
                </Box>
            </Grid>
          </Grid>
          <Grid item xs={5} id="siteslayout" style={{ paddingLeft: 10 }}>
            <Grid item xs={12} sx={{ ml: 11, pl: 11, display: "flex" }}>
              <div>
                <div style={wrapperStyle}>
                  <Typography style={labelStyle}>Filter by:</Typography>
                  <div style={actionStyle}>
                  <Button
                    variant={selectedAreaGroupFilter === 'District' ? 'contained' : 'text'}
                    style={buttonStyle}
                    onClick={() => handleAreaGroupFilterClick('District')}
                    key="District">District</Button>
                  <Button
                    variant={selectedAreaGroupFilter === 'MPO' ? 'contained' : 'text'}
                    style={buttonStyle}
                    onClick={() => handleAreaGroupFilterClick('MPO')}
                    key="MPO">MPO</Button>
                  </div>
                </div>
                <div style={wrapperStyle}>
                  <Typography style={labelStyle}>Select location: </Typography>
                  <Autocomplete
                    id="area-lists"
                    sx={{ ...actionStyle, display: 'flex', justifyContent: 'center' }}
                    value={selectedArea}
                    onChange={(event, newValue)=>handleAreaSelected(newValue)}
                    options={selectedAreaGroupFilterData.sort((a, b) =>
                      a.hasOwnProperty('districtName') ?
                      a.districtName.localeCompare(b.districtName)
                      : a.mpoName.localeCompare(b.mpoName))}
                    getOptionLabel={getLocationOptionLabel}
                    isOptionEqualToValue={(option, value) => true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Select a ${selectedAreaGroupFilter}`}
                        placeholder={selectedAreaGroupFilter}
                      />
                    )}
                  />
                </div>
                <div style={wrapperStyle}>
                  <Typography style={labelStyle}>Select site:&nbsp;</Typography>
                  <Autocomplete
                    style={actionStyle}
                    id="parking-site"
                    value={selectedSite}
                    onChange={handleSiteSelected}
                    options={selectedSiteTypeData.sort((a, b) =>
                      a.name.localeCompare(b.name))}
                    getOptionLabel={getSiteOptionLabel}
                    isOptionEqualToValue={(option, value) => true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a site"
                        placeholder="Sites"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <Divider
                  component="div"
                  role="presentation"
                  textAlign="center"
                  id="sites_dashboard_overview"
                  // sx={{ marginTop: 2.5, marginBottom: 2.5 }}
                  sx={{ margin: 2.5, mt: 0.5, color:'black' }}
                >
                  <Typography variant="h4">{selectedSite.name} Overview</Typography>
                </Divider>

                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    sx={{ height: "50px" }}
                  >
                    <Typography>
                      <Checkbox
                        defaultChecked
                        onChange={() => setPark(!park)}
                      />
                      <Link to={`/parking-sites?siteName=${siteNameFromUrl}#sites_park_summary`} smooth>
                        Parking Events Summary
                      </Link>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      Table 1 – Unofficial Parking Rate Percent: This table
                      provides a summary of the parking events for the selected
                      year and month by parking duration. This includes the
                      unofficial parking rate, which is an indicator of how much
                      parking is occurring outside of the official parking
                      spaces.
                    <br></br>
                      Table 2 – Average Daily Parking Usage: This table provides
                      a summary of the average daily parking usage, including an
                      expansion factor.
                  </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    sx={{ height: "50px" }}
                  >
                    <Typography>
                      <Checkbox
                        defaultChecked
                        onClick={() => handleToggle("", "hour")}
                      />
                      <Link to={`/parking-sites?siteName=${siteNameFromUrl}#sites_util_hour`} smooth>
                        Utilization by Hour
                      </Link>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      These figures provide a summary of the parking events by
                      hour for each month included in the data, one for those
                      parking events in authorized parking locations and one for
                      those parking events in unauthorized parking locations by
                      parking duration (selection above the figures).
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    sx={{ height: "50px" }}
                  >
                    <Typography>
                      <Checkbox
                        defaultChecked
                        onClick={() => handleToggle("", "day")}
                      />
                      <Link to={`/parking-sites?siteName=${siteNameFromUrl}#sites_util_day`} smooth>
                        Utilization by Day
                      </Link>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      These figures provide a summary of the parking events by
                      hour for each month included in the data, one for those
                      parking events in authorized parking locations and one for
                      those parking events in unauthorized parking locations by
                      parking duration (selection above the figures).
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>

          {park && (
            <Grid container spacing={0} className={classesGrid.gridRow1}>
              <Grid item xs>
                <div id={"stateDataPanelSites"} sx={{ height: "75vh" }}>
                  <TablePanelSites
                    unauthorizedPercentTable={unauthorizedPercentTable}
                    spaceRatioData={spaceRatioData}
                    expansionFactor={expansionFactor}
                  ></TablePanelSites>
                </div>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            {unofficialDataDay && selectedSite.name && (
              <div>
                {siteType === "Public" && (
                  <ChartsLayoutSites
                    parkingSiteGeom={parkingSiteGeom}
                    chartHeight={chartHeight}
                    siteOffUnoffByYearMonthHourData={
                      siteOffUnoffByYearMonthHourData
                    }
                    siteOffUnoffByYearMonthDayData={
                      siteOffUnoffByYearMonthDayData
                    }
                  />
                )}
                {siteType === "Private" && (
                  <ChartsLayoutPrivateSites
                    parkingSiteGeom={parkingSiteGeom}
                    chartHeight={chartHeight}
                    siteOffUnoffByYearMonthHourData={
                      siteOffUnoffByYearMonthHourData
                    }
                    siteOffUnoffByYearMonthDayData={
                      siteOffUnoffByYearMonthDayData
                    }
                  />
                )}
              </div>
            )}
          </Grid>
        </Grid>

        <OptionsBar
          statusGuide={statusGuide}
          showVehicleWeightClass={false}
          showDayType={false}
          showYear={true}
          showMonth={true}
          showGridResolution={false}
          showOpacity={true}
        />
      </div>
  );
}

export default SitesLayout;
