import React, { useState, useContext, useMemo } from "react";

/* Import component dependencies from material ui */
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";

/* Import modules from other folders as required */
import {
  dayTypeOptions,
  vehicleWeightClassOptions,
  sitesYearsMonths,
  yearsMonths
} from "../common/constants";
import { QueryContext } from "../context/QueryContext";
import ScrollArrow from "./ScrollTop";

const OptionsBar = (props) => {
  const [query, dispatch] = useContext(QueryContext);
  const {
    statusGuide,
    showVehicleWeightClass,
    showDayType,
    showYear,
    showMonth,
    showGridResolution,
    showOpacity,
  } = props;
  const handleChangeVehicleWeightClass = (event) => {
    // setSelectedVehicleWeightClass(parseInt(event.target.value));
    // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
    // else setDeckGlMapTypeOptionsEnabled(false);
    dispatch({
      type: "setSelectedVehicleWeightClass",
      selection: parseInt(event.target.value),
    });
  };
  const handleChangeDayType = (event) => {
    // setSelectedDayType(event.target.value);
    // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
    // else setDeckGlMapTypeOptionsEnabled(false);

    // console.log("day-type-check", event.target.value);
    dispatch({
      type: "setSelectedDayType",
      selection: event.target.value,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleWeightClassMenuItemClick = (event, index) => {
    setSelectedIndex(index);

    dispatch({
      type: "setSelectedVehicleWeightClass",
      selection: vehicleWeightClassOptions[index].value,
    });
    setAnchorEl(null);
  };

  const [anchorElDT, setAnchorElDT] = React.useState(null);
  const [selectedIndexDT, setSelectedIndexDT] = React.useState(1);
  const openDT = Boolean(anchorElDT);
  const handleClickListItemDT = (event) => {
    setAnchorElDT(event.currentTarget);
  };
  const handleDayTypeMenuItemClick = (event, index) => {
    setSelectedIndexDT(index);

    dispatch({
      type: "setSelectedDayType",
      selection: dayTypeOptions[index].label,
    });
    setAnchorElDT(null);
  };

  const sitesYears = [...new Set(sitesYearsMonths.map((item) => item.year))];
  const stateYears = [...new Set(yearsMonths.map((item) => item.year))];
  //   const sitesMonths = [
  //     ...new Set(
  //       sitesYearsMonths.filter((obj) => obj.year === query.selectedSite.year)
  //       // .map((item) => item.month)
  //     ),
  //   ];
  const [sitesMonths, setSitesMonths] = useState([
    ...new Set(
      sitesYearsMonths.filter((obj) => obj.year === query.selectedSite.year)
      // .map((item) => item.month)
    ),
  ]);
  const [stateMonths, setStateMonths] = useState([
    ...new Set(
      yearsMonths.filter((obj) => obj.year === query.statewide.year)
      // .map((item) => item.month)
    ),
  ]);
  useMemo(() => {
    setSitesMonths([
      ...new Set(
        sitesYearsMonths.filter((obj) => obj.year === query.selectedSite.year)
        // .map((item) => item.month)
      ),
    ]);
    setStateMonths([
      ...new Set(
        yearsMonths.filter((obj) => obj.year === query.statewide.year)
      ),
    ]);
  }, [query.selectedSite.year, query.statewide.year]);

  const [anchorElYr, setAnchorElYr] = React.useState(null);
  const [selectedIndexYr, setSelectedIndexYr] = React.useState(1);
  const openYr = Boolean(anchorElYr);
  const handleClickListItemYr = (event) => {
    setAnchorElYr(event.currentTarget);
  };
  const handleYearMenuItemClick = (event, index) => {

    setSelectedIndexYr(index);
    if (statusGuide === 'ParkingSites') {
      let selectedSiteTemp = query.selectedSite;
      let yearMonthTemp = sitesYearsMonths.filter(
        (obj) =>
          obj.year === sitesYears[index] && obj.month === selectedSiteTemp.month
      )[0].yearMonth;

      selectedSiteTemp.year = sitesYears[index];
      selectedSiteTemp.yearMonth = yearMonthTemp;
      dispatch({
        type: "setSelectedSite",
        selection: selectedSiteTemp,
      });
    } else if (statusGuide === 'State') {
      let selectedTemp = query.statewide;
      let yearMonthTemp = yearsMonths.filter(
        (obj) =>
          obj.year === stateYears[index] && obj.month === selectedTemp.month
      )[0].yearMonth;

      selectedTemp.year = stateYears[index];
      selectedTemp.yearMonth = yearMonthTemp;
      dispatch({
        type: "statewide",
        selection: selectedTemp,
      });
    }
    setAnchorElYr(null);
  };

  const [anchorElMonth, setAnchorElMonth] = React.useState(null);
  const [selectedIndexMonth, setSelectedIndexMonth] = React.useState(1);
  const openMonth = Boolean(anchorElMonth);
  const handleClickListItemMonth = (event) => {
    setAnchorElMonth(event.currentTarget);
  };
  const handleMonthMenuItemClick = (event, index) => {
    setSelectedIndexMonth(index);
    if (statusGuide === 'ParkingSites') {
      let selectedSiteTemp = query.selectedSite;

      selectedSiteTemp.month = sitesMonths[index].month;
      selectedSiteTemp.yearMonth = sitesMonths[index].yearMonth;
      dispatch({
        type: "setSelectedSite",
        selection: selectedSiteTemp,
      });
    } else if (statusGuide === 'State') {
      let selectedTemp = query.statewide;

      selectedTemp.month = stateMonths[index].month;
      selectedTemp.yearMonth = stateMonths[index].yearMonth;
      dispatch({
        type: "statewide",
        selection: selectedTemp,
      });
    }

    setAnchorElMonth(null);
  };

  const [anchorElYearMonth, setAnchorElYearMonth] = React.useState(null);
  const [selectedIndexYearMonth, setSelectedIndexYearMonth] = React.useState(1);
  const openYearMonth = Boolean(anchorElYearMonth);
  const handleClickListItemYearMonth = (event) => {
    setAnchorElYearMonth(event.currentTarget);
  };
  const handleYearMonthMenuItemClick = (event, index) => {
    setSelectedIndexYearMonth(index);

    let selectedSiteTemp = query.selectedSite;

    selectedSiteTemp.year = sitesYearsMonths[index].year;
    selectedSiteTemp.month = sitesYearsMonths[index].month;
    selectedSiteTemp.yearMonth = sitesYearsMonths[index].yearMonth;
    dispatch({
      type: "setSelectedSite",
      selection: selectedSiteTemp,
    });
    setAnchorElYearMonth(null);
  };

  const stateMapGridResOptions = [6, 7, 8, 9, 10];
  const [anchorElMapGridRes, setAnchorElMapGridRes] = React.useState(null);
  const [selectedIndexMapGridRes, setSelectedIndexMapGridRes] =
    React.useState(1);
  const openMapGridRes = Boolean(anchorElMapGridRes);
  const handleClickListItemMapGridRes = (event) => {
    setAnchorElMapGridRes(event.currentTarget);
  };
  const handleMapGridResMenuItemClick = (event, index) => {
    setSelectedIndexMapGridRes(index);

    dispatch({
      type: "setStateMapGridResolution",
      selection: stateMapGridResOptions[index],
    });
    setAnchorElMapGridRes(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElDT(null);
    setAnchorElYr(null);
    setAnchorElYearMonth(null);
    setAnchorElMapGridRes(null);
  };

  return (
    <Paper className="optionsBar">
      <Toolbar variant='dense' disableGutters
        sx={{ backgroundColor: "#14375a", minHeight: 20, height: 35, paddingLeft: 1.5 }}>

        <ScrollArrow />

        <Typography variant="subtitle1" color="white">
          Data Options:{" "}
        </Typography>

        <Button
          id="weight-class-button"
          aria-controls={open ? "weight-class-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          // variant="outlined"
          disableElevation
          onClick={handleClickListItem}
          endIcon={<KeyboardArrowDownIcon />}
          //   color="secondary"
          //   color="#ffffff"
          // size="small"
          sx={{
            fontSize: 12,
            p: 0,
            m: 0,
            ml: 2.5,
            mr: 2.5,
            color: "white",
            display: showVehicleWeightClass ? "default" : "none"
          }}
        >
          Vehicle Weight Class
        </Button>
        <Menu
          id="weight-class-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "weight-class-button",
            role: "listbox",
          }}
          sx={{
            display: showVehicleWeightClass ? "default" : "none"
          }}
        >
          {vehicleWeightClassOptions.map((option, index) => (
            <MenuItem
              key={"vehicleweightclass-" + option.value}
              // disabled={index === 0}
              selected={index === selectedIndex}
              onClick={(event) =>
                handleWeightClassMenuItemClick(event, index)
              }
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>

        <Button
          id="day-type-button"
          aria-controls={openDT ? "day-type-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openDT ? "true" : undefined}
          // variant="contained"
          disableElevation
          onClick={handleClickListItemDT}
          endIcon={<KeyboardArrowDownIcon />}
          color="secondary"
          // size="small"
          sx={{
            fontSize: 12,
            p: 0,
            m: 0,
            ml: 2.5,
            mr: 2.5,
            color: "white",
            display: showDayType ? "default" : "none"
          }}
        >
          Day Type
        </Button>
        <Menu
          id="day-type-menu"
          anchorEl={anchorElDT}
          open={openDT}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "day-type-button",
            role: "listbox",
          }}
          sx={{
            display: showDayType ? "default" : "none"
          }}
        >
          {dayTypeOptions.map((option, index) => (
            <MenuItem
              key={"day-type-" + option.value}
              // disabled={index === 0}
              selected={index === selectedIndexDT}
              onClick={(event) => handleDayTypeMenuItemClick(event, index)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>

        <Button
          id="year-button"
          aria-controls={openYr ? "year-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openYr ? "true" : undefined}
          // variant="outlined"
          disableElevation
          onClick={handleClickListItemYr}
          endIcon={<KeyboardArrowDownIcon />}
          color="secondary"
          // size="small"
          sx={{
            fontSize: 12,
            p: 0,
            m: 0,
            ml: 2.5,
            mr: 2.5,
            color: "white",
            display: showYear ? "default" : "none"
          }}
        >
          Year
        </Button>
        <Menu
          id="weight-class-menu"
          anchorEl={anchorElYr}
          open={openYr}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "year-button",
            role: "listbox",
          }}
          sx={{
            display: showYear ? "default" : "none"
          }}
        >
          {statusGuide === 'ParkingSites' && sitesYears.map((option, index) => (
            <MenuItem
              key={"year-" + option}
              // disabled={index === 0}
              selected={index === selectedIndexYr}
              onClick={(event) => handleYearMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
          {statusGuide === 'State' && stateYears.map((option, index) => (
            <MenuItem
              key={"year-" + option}
              // disabled={index === 0}
              selected={index === selectedIndexYr}
              onClick={(event) => handleYearMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>

        <Button
          id="month-button"
          aria-controls={openMonth ? "month-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMonth ? "true" : undefined}
          // variant="outlined"
          disableElevation
          onClick={handleClickListItemMonth}
          endIcon={<KeyboardArrowDownIcon />}
          color="secondary"
          // size="small"
          sx={{
            fontSize: 12,
            p: 0,
            m: 0,
            ml: 2.5,
            mr: 2.5,
            color: "white",
            display: showMonth ? "default" : "none"
          }}
        >
          Month
        </Button>
        <Menu
          id="month-menu"
          anchorEl={anchorElMonth}
          open={openMonth}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "month-button",
            role: "listbox",
          }}
          sx={{
            display: showMonth ? "default" : "none"
          }}
        >
          {statusGuide === 'ParkingSites' && sitesMonths.map((option, index) => (
            <MenuItem
              key={"month-" + option.yearMonth}
              // disabled={index === 0}
              selected={index === selectedIndexMonth}
              onClick={(event) => handleMonthMenuItemClick(event, index)}
            >
              {option.yearMonth.split("-")[1]}
            </MenuItem>
          ))}
          {statusGuide === 'State' && stateMonths.map((option, index) => (
            <MenuItem
              key={"month-" + option.yearMonth}
              // disabled={index === 0}
              selected={index === selectedIndexMonth}
              onClick={(event) => handleMonthMenuItemClick(event, index)}
            >
              {option.yearMonth.split("-")[1]}
            </MenuItem>
          ))}
        </Menu>

        {/* <Button
          id="year-month-button"
          aria-controls={
            openYearMonth ? "year-month-customized-menu" : undefined
          }
          aria-haspopup="true"
          aria-expanded={openYearMonth ? "true" : undefined}
          // variant="outlined"
          disableElevation
          onClick={handleClickListItemYearMonth}
          endIcon={<KeyboardArrowDownIcon />}
          color="secondary"
          // size="small"
          sx={{ fontSize: 12, p: 0, m: 0, ml: 2.5, mr: 2.5, color: "white" }}
        >
          Year-Month
        </Button>
        <Menu
          id="year-month-menu"
          anchorEl={anchorElYearMonth}
          open={openYearMonth}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "year-month-button",
            role: "listbox",
          }}
        >
          {sitesYearsMonths.map((option, index) => (
            <MenuItem
              key={"year-month-" + option}
              // disabled={index === 0}
              selected={index === selectedIndexYearMonth}
              onClick={(event) => handleYearMonthMenuItemClick(event, index)}
            >
              {option.yearMonth}
            </MenuItem>
          ))}
        </Menu> */}
        {(showGridResolution || showOpacity) && (<Typography variant="subtitle1" color="white"
          sx={{ ml: 5 }}>
          Map Options:
        </Typography>)}

        <Button
          id="map-grid-res-button"
          aria-controls={
            openMapGridRes ? "map-grid-res-customized-menu" : undefined
          }
          aria-haspopup="true"
          aria-expanded={openMapGridRes ? "true" : undefined}
          // variant="outlined"
          disableElevation
          onClick={handleClickListItemMapGridRes}
          endIcon={<KeyboardArrowDownIcon />}
          color="secondary"
          // size="small"
          sx={{
            fontSize: 12,
            p: 0,
            m: 0,
            ml: 2.5,
            mr: 2.5,
            color: "white",
            display: showGridResolution ? "default" : "none"
          }}
        >
          Grid Resolution
        </Button>
        <Menu
          id="map-grid-res-menu"
          anchorEl={anchorElMapGridRes}
          open={openMapGridRes}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "map-grid-res-button",
            role: "listbox",
          }}
          sx={{
            display: showGridResolution ? "default" : "none"
          }}
        >
          {stateMapGridResOptions.map((option, index) => (
            <MenuItem
              key={"map-grid-res-" + option}
              // disabled={index === 0}
              selected={index === selectedIndexMapGridRes}
              onClick={(event) =>
                handleMapGridResMenuItemClick(event, index)
              }
            >
              {option}
            </MenuItem>
          ))}
        </Menu>

        <Typography
          //   variant="subtitle1"
          color="white"
          sx={{
            fontSize: 13,
            ml: 2.5,
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
        >
          Opacity
        </Typography>
        <Box
          sx={{
            fontSize: 12,
            width: 100,
            p: 0,
            m: 0,
            ml: 2.5,
            mr: 2.5,
            color: "white",
            display: showOpacity ? "default" : "none"
          }}
        >
          <Slider
            aria-label="Map Opacity"
            defaultValue={0.8}
            value={query.mapOpacity}
            // getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={0.1}
            marks
            min={0}
            max={1}
            sx={{
              fontSize: 12,
              color: "white",
            }}
            onChangeCommitted={(_, v) =>
              dispatch({
                type: "setMapOpacity",
                selection: v,
              })
            }
          />
        </Box>

      </Toolbar>
    </Paper>
  );
};

export default OptionsBar;
