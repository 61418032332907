import React, { useState, useEffect, useContext } from "react";

import {
  // geographies,
  // vehicleWeightClassOptions,
  parkingSites,
  parkingDurationOptions,
  sitesYearsMonths,
  months,
} from "../common/constants";

import Grid from "@mui/material/Grid";


import StackedBarChart from "./HighCharts/StackedBarChart";
import HighContrasLight from "highcharts/themes/high-contrast-light";

import { QueryContext } from "../context/QueryContext";


import { gridStyles } from "../ui/styles";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// import BoxPlot from "./HighCharts/BoxPlot";

import {
  // fetchStatewideData,
  // fetchParkingDataRegionByYearMonth,
  // fetchParkingDataCountyByYearMonth,
  // fetchParkingDataByRegionYearMonth,
  // fetchLandUse,
  // fetchParkingSitesGeom,
  // findParkingSiteEvents,
  findSiteUnoffRatioByDirYearMonthDay,
  // findSiteOffUnoffByYearMonthDay,
  // findSiteOffUnoffByYearMonthHour,
} from "../data-loaders/DataLoaders";

import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

function ChartsLayoutPrivateSites(props) {
  const [query, dispatch] = useContext(QueryContext);
  const classesGrid = gridStyles();
  //   const classes = useStyles();
  const {
    // statusGuide,
    parkingSiteGeom,
    siteOffUnoffByYearMonthHourData,
    siteOffUnoffByYearMonthDayData,
  } = props;
  // console.log("siteSelected-check", siteSelected);
  const [unofficialDataChart, setUnofficialDataChart] = useState(null);
  // useEffect(() => {
  //   if (unofficialDataDay !== null) {
  //     console.log("unofficialDataDay-check-layout", unofficialDataDay);
  //     setUnofficialDataChart(unofficialDataDay);
  //   }
  // }, [unofficialDataDay]);

  // console.log("unofficialDataChart-check", unofficialDataChart);
  // useEffect(() => {
  //   console.log(
  //     "siteOffUnoffByYearMonthHourData-check",
  //     siteOffUnoffByYearMonthHourData
  //   );
  // }, [siteSelected]);
  let hourlyCountsSummaryVars = [
    "unauth_ratio",
    "unauth1_ratio",
    "unauth1_3_ratio",
    "unauth3_7_ratio",
    "unauth7_11_ratio",
    "unauth11_ratio",
  ];
  useEffect(() => {
      // let tempDataTable = [];
      // [
      //   "less than 1 hour",
      //   "1-3 hours",
      //   "3-7 hours",
      //   "7-11 hours",
      //   "greater than 11 hours",
      // ].map((obj) => {
      //   let dataTemp = calcUnauthorizedPercent(obj, siteEventsGeom);
      //   // console.log("dataTemp-check", dataTemp);
      //   tempDataTable.push(dataTemp);
      // });
      // let totalCount = sumByName(tempDataTable, "totalCount");
      // let authorizedCount = sumByName(tempDataTable, "authorizedCount");
      // let unauthorizedCount = sumByName(tempDataTable, "unauthorizedCount");
      // let percentUnauthorized =
      //   totalCount > 0 ? (unauthorizedCount / totalCount) * 100 : 0;
      // tempDataTable.push({
      //   siteName: statusGuide,
      //   yearMonth: siteSelected.yearMonth,
      //   parkingCategory: "Total",
      //   totalCount: totalCount,
      //   authorizedCount: authorizedCount,
      //   unauthorizedCount: unauthorizedCount,
      //   percentUnauthorized: percentUnauthorized,
      // });
      // setUnauthorizedPercentTable(tempDataTable);

      // let tempSpaceRatioData = [];
      let siteDirs = parkingSites.filter(
        (site) => site.name === query.selectedSite.selected
      )[0].dir;
      // console.log("siteDirs-check", siteDirs);
      siteDirs.map((ste, index) => {
        // console.log("ste-check", ste);
        let siteLabel =
          query.selectedSite.selected +
          (ste === "N"
            ? " - Northbound"
            : ste === "S"
            ? " - Southbound"
            : ste === "W"
            ? " - Westbound"
            : ste === "E"
            ? " - Eastbound"
            : "");
        // let dailyAveTotalCount = (
        //   siteEventsGeom.filter(
        //     (obj) => obj.sitename === query.selectedSite.selected && obj.dir === ste
        //   ).length / 31
        // ).toFixed(0);

        // let tempSiteData = [];
        // parkingSiteGeom.features.map((fc) =>
        //   tempSiteData.push(fc.properties)
        // );

        // let spaces = 0;
        // if (
        //   tempSiteData.filter(
        //     (fc) => fc.dir === ste && fc.authorized === 1
        //   )[0]
        // )
        //   spaces = tempSiteData.filter(
        //     (fc) => fc.dir === ste && fc.authorized === 1
        //   )[0].space;
        // tempSpaceRatioData.push({
        //   siteName: query.selectedSite.selected,
        //   dir: ste,
        //   yearMonth: siteSelected.yearMonth,
        //   siteLabel: siteLabel,
        //   totalCount: dailyAveTotalCount,
        //   spaces: spaces,
        //   spaceRatio: spaces > 0 ? (dailyAveTotalCount / spaces) * 100 : 0,
        // });

        findSiteUnoffRatioByDirYearMonthDay(
          query.selectedSite.selected,
          ste,
          query.selectedSite.year,
          query.selectedSite.month
        ).then((response) => {
          // console.log("response-check", response);
          // setSiteEventsGeom(response.data);

          let daysTemp = [];
          let chartDataTemp = [];
          // hourlyCountsSummaryVars.map(() => {
          //   chartDataTemp.push([]);
          // });

          response.data.map((data) => {
            daysTemp.push(data.start_day);

            hourlyCountsSummaryVars.map((item, index) => {
              chartDataTemp[index].push(data[item]);
            });
          });

          // tempUnofficialRatioDataDay.push({
          //   siteName: query.selectedSite.selected,
          //   dir: ste,
          //   yearMonth: query.selectedSite.yearMonth,
          //   siteLabel: siteLabel,
          //   days: daysTemp,
          //   chartData: [
          //     hourlyCountsSummaryVars.map((item, index) => {
          //       return { name: item, data: chartDataTemp[index] };
          //     }),
          //   ],
          // });
          let mergedChartData = [];

          hourlyCountsSummaryVars.map((item, index) => {
            mergedChartData = [
              ...mergedChartData,
              { name: item, data: chartDataTemp[index] },
            ];
          });
          if (index === 0)
            setUnofficialDataChart({
              siteName: query.selectedSite.selected,
              dir: ste,
              yearMonth: query.selectedSite.yearMonth,
              siteLabel: siteLabel,
              days: daysTemp,
              chartData: mergedChartData,
            });
        });

        // findSiteOffUnoffByYearMonthDay(
        //   query.selectedSite.selected,
        //   ste,
        //   query.selectedSite.year
        // ).then((response) => {
        //   console.log("response-off-unoff-yr-day-check", response);
        // });
        // findSiteOffUnoffByYearMonthHour(
        //   query.selectedSite.selected,
        //   ste,
        //   query.selectedSite.year
        // ).then((response) => {
        //   console.log("response-off-unoff-yr-hour-check", response);
        // });
      });
      // setSpaceRatioData(tempSpaceRatioData);
      // setUnofficialDataChart(tempUnofficialRatioDataDay);
  }, [query.selectedSite]);
  // console.log("unofficialDataChart-check", unofficialDataChart);
  // console.log(
  //   "siteOffUnoffByYearMonthHourData-check",
  //   siteOffUnoffByYearMonthHourData
  // );

  function setChartDataOffUnoffByYearMonthHourData(
    direction,
    category  ) {
    let tempHourData = siteOffUnoffByYearMonthHourData.filter(
      (obj) => obj.direction === direction
    );
    // let startMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let startMonths = [];
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((option) => startMonths.push(option.month));
    let chartData = [];
    // monthsDescription.map((itm) => {
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((itm) => {
        // let obj = itm.value;
        let monthObj = itm.month;
        let tempData = [];
        tempHourData
          .filter((hrData) => hrData.start_month === monthObj)
          .map((dt) => (tempData[dt.start_hour] = dt[category]));
        chartData.push({
          // name: "Month " + obj.toLocaleString(),
          // name: itm.text,
          name: months[monthObj].description,
          data: tempData,
          // offset: 0,
          // yAxis: yAxisNumber,
          // xAxis: yAxisNumber,
          // showInLegend: showInLegend,
          // id: obj.toLocaleString() + "-month-" + yAxisNumber,
          id: monthObj.toLocaleString() + "-month",
          // pointWidth: 5,
          // groupPadding: 0,
          // pointPadding: 0,
          // type: "column",
          color: itm.color,
        });
      });

    // console.log("chartData-check", chartData);
    return chartData;
  }
  // console.log(
  //   "siteOffUnoffByYearMonthDayData-check",
  //   siteOffUnoffByYearMonthDayData
  // );
  function setChartDataOffUnoffByYearMonthDayData(
    direction,
    category  ) {
    let tempDayData = siteOffUnoffByYearMonthDayData.filter(
      (obj) => obj.direction === direction
    );
    // let startMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let startMonths = [];
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((option) => startMonths.push(option.month));

    let chartData = [];
    // monthsDescription.map((itm) => {
    sitesYearsMonths
      .filter((itm) => itm.year === query.selectedSite.year)
      .map((itm) => {
        let monthObj = itm.month;
        let tempData = [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];
        tempDayData
          .filter((dayData) => dayData.start_month === monthObj)
          .map((dt) => (tempData[dt.start_day] = dt[category]));
        // console.log("tempData-day-check", tempData);
        chartData.push({
          // name: "Month " + obj.toLocaleString(),
          name: months[monthObj].description,
          data: tempData,
          // offset: 0,
          // yAxis: yAxisNumber,
          // xAxis: yAxisNumber,
          // showInLegend: showInLegend,
          // id: obj.toLocaleString() + "-month-" + yAxisNumber,
          // id: obj.toLocaleString() + "-month",
          // pointWidth: 5,
          // groupPadding: 0,
          // pointPadding: 0,
          // type: "column",
          color: itm.color,
        });
      });

    // console.log("chartData-check", chartData);
    return chartData;
  }

  const [selectedSitesDuration, setSelectedSitesDuration] = useState(
    parkingDurationOptions[0].value
  );

  const handleChangeParkingDuration = (event) => {
    setSelectedSitesDuration(event.target.value);
    // if (event.target.value === "leaflet") setDeckGlMapTypeOptionsEnabled(true);
    // else setDeckGlMapTypeOptionsEnabled(false);
    // dispatch({
    //   type: "setSelectedVehicleWeightClass",
    //   selection: event.target.value,
    // });
  };

  function setChartTitle(year, site, dir) {
    if (dir === "N") return year + " " + site + " Northbound";
    if (dir === "S") return year + " " + site + " Southbound";
    if (dir === "E") return year + " " + site + " Eastbound";
    if (dir === "W") return year + " " + site + " Westbound";
    if (dir === "B") return year + " " + site;

    return year + " " + site;
  }

  return (
    <div>
      {parkingSites
        .filter((itm) => itm.name === query.selectedSite.selected)[0]
        .dir.map((dir) => {
          return (
            <Grid
              container
              spacing={0}
              // style={{ height: "41vh" }}
              // style={{ height: "65" }}
              // className={classesGrid.gridRow1}
              sx={{ mb: 2.5 }}
            >
              <Grid item xs={12} style={{ paddingop: 10 }}>
                <Divider component="div" role="presentation" textAlign="center">
                  {/* any elements nested inside the role="presentation" preserve their semantics. */}

                  <Chip
                    label={
                      <Typography
                        variant="h5"
                        // component="div"
                        // sx={{ padding: "5px", textAlign: "center" }}
                        sx={{ margin: 5, padding: 5 }}
                      >
                        Utilization
                      </Typography>
                    }
                    sx={{ backgroundColor: "#a9a9a9" }}
                  />
                </Divider>
              </Grid>

              <Grid item xs={12}>
                <FormControl className={classesGrid.formLabel}>
                  <FormLabel
                    id="sites-duration-radio-buttons-group-label"
                    className={classesGrid.formLabel}
                  >
                    Parking Duration
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="duration-radio-buttons-group"
                    value={selectedSitesDuration}
                    onChange={handleChangeParkingDuration}
                    className={classesGrid.formLabel}
                    row
                  >
                    {parkingDurationOptions.map((option) => (
                      <FormControlLabel
                        // value="mapbox://styles/mapbox/streets-v11"
                        value={option.value}
                        control={<Radio size="small" />}
                        label={option.label}
                        key={"sitesDurations-" + option.value}
                        // className={classesGrid.formLabel}
                        // disabled={false}
                        // disabled={regionSelected.year === 2018 ? true : false}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {query.selectedComponents.utilizationByHour && (
                <Grid
                  item
                  xs={6}
                  id="sites_util_hour"
                  style={{ paddingRight: 5, paddingTop: 10 }}
                >
                  <StackedBarChart
                    // chartData={setChartDataOffUnoffByYearMonthHourData(
                    //   "N",
                    //   "total_auth1"
                    // )}
                    chartData={setChartDataOffUnoffByYearMonthHourData(
                      dir,
                      parkingDurationOptions.filter(
                        (durationOpt) => durationOpt.value === selectedSitesDuration
                      )[0].authorizedField
                    )}
                    plotOptionsColumnStacking={"normal"}
                    plotOptionsColumnDataLabelsEndabled={false}
                    chartHeight={"50%"}
                    themeHC={HighContrasLight}
                    yAxisTitleText={"# of Parking Events"}
                    legendSpecs={{
                      layout: "vertical",
                      align: "right",
                      verticalAlign: "middle",
                      itemMarginTop: 0.5,
                      itemMarginBottom: 0,
                      itemStyle: {
                        // color: "#000000",
                        // fontWeight: "bold",
                        fontSize: 10.5,
                      },
                    }}
                    titleText={
                      setChartTitle(
                        query.selectedSite.year,
                        query.selectedSite.selected,
                        dir
                      ) + " Average Utilization by Hour"
                    }
                    subtitleText={
                      selectedSitesDuration !== "all"
                        ? "Parking " +
                          parkingDurationOptions.filter(
                            (durationOpt) => durationOpt.value === selectedSitesDuration
                          )[0].label
                        : " "
                    }
                    xAxisTitle={{
                      enabled: true,
                      text: "Hour (Hour 0 is 12 a.m.)",
                    }}
                  />
                </Grid>
              )}
              {query.selectedComponents.utilizationByDay && (
                <Grid
                  item
                  xs={6}
                  id="sites_util_day"
                  style={{ paddingLeft: 5, paddingTop: 10 }}
                >
                  <StackedBarChart
                    // chartData={setChartDataOffUnoffByYearMonthHourData(
                    //   "N",
                    //   "total_auth1"
                    // )}
                    chartData={setChartDataOffUnoffByYearMonthDayData(
                      dir,
                      parkingDurationOptions.filter(
                        (duration) => duration.value === selectedSitesDuration
                      )[0].authorizedField
                    )}
                    plotOptionsColumnStacking={"normal"}
                    plotOptionsColumnDataLabelsEndabled={false}
                    chartHeight={"50%"}
                    themeHC={HighContrasLight}
                    yAxisTitleText={"# of Parking Events"}
                    legendSpecs={{
                      layout: "vertical",
                      align: "right",
                      verticalAlign: "middle",
                      itemMarginTop: 0.5,
                      itemMarginBottom: 0,
                      itemStyle: {
                        // color: "#000000",
                        // fontWeight: "bold",
                        fontSize: 10.5,
                      },
                    }}
                    titleText={
                      setChartTitle(
                        query.selectedSite.year,
                        query.selectedSite.selected,
                        dir
                      ) + " Average Utilization by Day"
                    }
                    subtitleText={
                      selectedSitesDuration !== "all"
                        ? "Parking " +
                          parkingDurationOptions.filter(
                            (duration) => duration.value === selectedSitesDuration
                          )[0].label
                        : " "
                    }
                    xAxisTitle={{
                      enabled: true,
                      text: "Day",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
    </div>
  );
}

export default ChartsLayoutPrivateSites;
