import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LayersIcon from "@material-ui/icons/Layers";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";

import { baseLayers } from "../../common/constants";

import Checkbox from "@mui/material/Checkbox";

const theme = createTheme({});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  formGroup: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(8),
  },
  formLabel: {
    padding: 0,
    margin: 0,
    fontSize: theme.typography.pxToRem(8),
  },
  input: {
    display: "none",
  },
  colselectColumn1: {
    paddingLeft: 5,
    textAlign: "left",
  },
  colselectColumn2: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: theme.typography.pxToRem(8),
  },
  customWidth: {
    "& div": {
      width: "21vw",
    },
    height: "70vh",
  },
  filterIcon: {
    // background: theme.palette.background.paper,
    background: theme.palette.layersControl.paper,
  },
  selectedFeaturesLabel: {
    paddingLeft: 15,
    fontWeight: 500,
  },
}));

const SitesLayerControls = (props) => {
  const { baseMap, setBaseMap, dataLayers } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("simple-menu").children[2];
      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetHeight,
        bottom: menu.offsetTop + menu.offsetHeight,
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setAnchorEl(null);
  };

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        handleClose(e);
      },
    },
  };

  const handleChangeBasemap = (event) => {
    setBaseMap(event.target.value);
  };

  const handleChangeDataLayer = (item, event) => {
    // console.log("item-check", item);
    // setShowLandUse(event.target.checked);
    item.setVisibility(event.target.checked);
  };

  const addLegendLine = (props) => {
    return (
      <Typography
        variant="subtitle2"
        className={classes.wrapIcon}
        key={props.name + "-legend"}
      >
        {props.name}
        {props.icon !== null && (
          <img
            alt="legend"
            className={classes.imageIcon}
            src={props.icon}
            style={{ marginLeft: "5px", width: 14, height: 14 }}
          />
        )}
      </Typography>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <IconButton
          color="primary"
          aria-label="filter"
          component="span"
          onMouseEnter={handleClick}
          onMouseLeave={handleClose}
        >
          <LayersIcon
            color="action"
            fontSize="large"
            className={classes.filterIcon}
          />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.customWidth}
        >
          <FormGroup className={classes.formGroup}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.formLabel}>
                Basemaps
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={baseMap}
                onChange={handleChangeBasemap}
                className={classes.formLabel}
              >
                {baseLayers.map((item, index) => (
                  <FormControlLabel
                    // value="mapbox://styles/mapbox/streets-v11"
                    value={"mapbox://styles/" + item.id}
                    control={<Radio size="small" />}
                    // label={item.name}
                    label={addLegendLine({ name: item.name, icon: null })}
                    key={`basemap-option-${index}`}
                    className={classes.formLabel}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </FormGroup>

          <FormGroup className={classes.formGroup}>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.formLabel}>
                Data
              </FormLabel>
              <FormGroup>
                {dataLayers.map((obj) => {
                  //   console.log("obj-check", obj);
                  return (
                    <FormControlLabel
                      // value="mapbox://styles/mapbox/streets-v11"
                      control={
                        <Checkbox
                          checked={obj.visibility}
                          onChange={(event) =>
                            handleChangeDataLayer(obj, event)
                          }
                          inputProps={{ "aria-label": "controlled" }}
                          value={obj}
                          size="small"
                        />
                      }
                      label={addLegendLine({ name: obj.label, icon: obj.icon })}
                      key={obj.key}
                      className={classes.formLabel}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </FormGroup>
        </Menu>
      </div>
    </ThemeProvider>
  );
};

SitesLayerControls.propTypes = {
  style: PropTypes.object,
};

export default SitesLayerControls;
