/**
 * @fileOverview the main UI component
 */

import React, { useState, useRef, useEffect, useContext } from "react";

import { HashLink as Link } from "react-router-hash-link";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

import "../App.scss";
import "../App.css";

import DeckglMapH3 from "../map/DeckglMapH3.js";

import TablePanel from "../table/TablePanel";

import { geographies } from "../common/constants";

import ChartsLayoutH3 from "../Charts/ChartsLayoutH3";

import OptionsBar from "../ui/OptionsBar";

function StatewideLayoutH3(props) {
  const { statusGuide } = props;

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";

  const stageCanvasRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(600);

  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(true);
  const [show3, setShow3] = useState(true);
  const [show4, setShow4] = useState(true);
  const [show5, setShow5] = useState(true);
  const [show6, setShow6] = useState(true);
  const [show7, setShow7] = useState(true);

  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (stageCanvasRef.current) {
      let height = stageCanvasRef.current.offsetHeight;
      //let width = stageCanvasRef.current.offsetWidth;

      setChartHeight(height);
    }
  }, [stageCanvasRef]);

  // const mapRef = React.useRef(null);

  const [regionSelected, setRegionSelected] = useState({
    selected: statusGuide,
    yearMonthOptionsIndex: 7,
    yearMonth: 7,
    // geographyOptionsIndex: 1,
    geographyOptionsIndex: geographies.findIndex(
      (obj) => obj.label === statusGuide
    ),
    regionParkingDataType: 0,
    parkingDurationCategories: [1, 2, 3, 4, 5],
  });

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <Grid item xs={8}>
          <Box id="map" className={mode} >
            <DeckglMapH3
              regionSelected={regionSelected}
              setRegionSelected={setRegionSelected}
            />
          </Box>
        </Grid>

        <Grid item xs={4} className="dashboardOverview">
          <div>
            <Divider
              component="div"
              role="presentation"
              textAlign="center"
              id="sites_dashboard_overview"
              // sx={{ marginTop: 2.5, marginBottom: 2.5 }}
              sx={{ margin: 2.5, mt: 0.5 , color:'black'}}
            >
              <Typography variant="h4">Statewide Overview</Typography>
            </Divider>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ height: "50px" }}
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onChange={() => setShow1(!show1)}
                  />
                  <Link to="#parkingevent" smooth>
                    Parking Events Summary
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Comparison of distribution of parking events across the
                parking duration categories by month.
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ height: "50px" }}
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onChange={() => setShow2(!show2)}
                  />
                  <Link to="#donut" smooth>
                    Parking events by Month, Parking Duration Category
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Donut chart showing the distribution of the statewide
                parking events for the selected month across the parking
                duration categories.
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ height: "50px" }}
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onChange={() => setShow3(!show3)}
                  />
                  <Link to="#stackedbar" smooth>
                    Parking Events by Day-of-Week
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Chart showing the distribution of the statewide parking
                events across the parking duration categories for the
                selected month by day of week.
              </AccordionDetails>
            </Accordion>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ height: "50px" }}
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onChange={() => setShow4(!show4)}
                  />
                  <Link to="#stackedbar" smooth>
                    Parking Events by Month
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Chart showing the distribution of the statewide parking
                events across the parking duration categories for the
                selected year by month.
              </AccordionDetails>
            </Accordion>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ height: "50px" }}
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onChange={() => setShow5(!show5)}
                  />
                  <Link to="#timeseries" smooth>
                    Parking Events by Duration
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Chart showing the distribution of the statewide parking
                events across the parking duration categories for the
                selected year by month.
              </AccordionDetails>
            </Accordion>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ height: "50px" }}
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onChange={() => setShow6(!show6)}
                  />
                  <Link to="#boxplot" smooth>
                    Truck Parking Events
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Statistics on the statewide parking events for the selected
                year and month by time-of-day.
              </AccordionDetails>
            </Accordion>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ height: "50px" }}
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onChange={() => setShow7(!show7)}
                  />
                  <Link to="#columnplot" smooth>
                    Truck Parking Events by Time-of-Day
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                Distribution of statewide parking events for the selected
                year and month by time-of-day.
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>

      {show1 && (
        <TablePanel
          show1={show1}
          titleDesc="Parking Events"
          regionSelected={regionSelected}
        />
      )}

      <ChartsLayoutH3
        statusGuide={statusGuide}
        chartHeight={chartHeight}
        regionSelected={regionSelected}
        show2={show2}
        show3={show3}
        show4={show4}
        show5={show5}
        show6={show6}
        show7={show7}
      />

      <OptionsBar
        statusGuide={statusGuide}
        showVehicleWeightClass={true}
        showDayType={true}
        showYear={true}
        showMonth={true}
        showGridResolution={true}
        showOpacity={true}
      />

    </React.Fragment>
  );
}

export default StatewideLayoutH3;
