import React, { useState, useRef, useEffect, useMemo, useContext } from "react";

import { QueryProvider, QueryContext } from "../context/QueryContext";
import { parkingSites } from "../common/constants";
import "../App.css";

import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";

import Typography from "@material-ui/core/Typography";
import { makeTheme, sidebarStyles, gridStyles } from "../ui/styles";
import "../App.scss";
import Grid from "@material-ui/core/Grid";

function Dashboard(props) {
  const {
    park,
    setPark,
    Nhour,
    setNhour,
    Shour,
    setShour,
    Ehour,
    setEhour,
    Whour,
    setWhour,
    Nday,
    setNday,
    Sday,
    setSday,
    Eday,
    setEday,
    Wday,
    setWday,
  } = props;

  const [query, dispatch] = useContext(QueryContext);

  // console.log(" hour", Nday,Nhour,Sday,Shour);
  function setChartTitle(year, site, dir) {
    if (dir === "N") return year + " " + site + " - Northbound";
    if (dir === "S") return year + " " + site + " - Southbound";
    if (dir === "E") return year + " " + site + " - Eastbound";
    if (dir === "W") return year + " " + site + " - Westbound";
    if (dir === "B") return year + " " + site;

    return year + " " + site;
  }
  function handleToggle(direction, time) {
    // console.log("hello toggle");
    // setNhour(!Nhour);
    // if(direction==="N"){
    //   if(time==="hour")
    //     setNhour(!Nhour);
    //   else
    //    setNday(!Nday);
    // }
    //  else if(direction==="S"){
    //     if(time==="hour")
    //     return setShour(!Shour);
    //     else
    //     return setSday(!Sday);
    //   }
    //   else if(direction==="E"){
    //     if(time==="hour")
    //     return setEhour(!Ehour);
    //     else
    //     return setEday(!Eday);
    //   }
    //  else {
    //     if(time==="hour")
    //     return setWhour(!Whour);
    //     else
    //     return setWday(!Wday);
    //   }
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        <a>Dashboard Overview</a>
      </Typography>

      {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography><Checkbox defaultChecked /><Link  to='#sitesmap'>Site Parking events on a map</Link></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Comparison of distribution of parking events across the parking duration categories by month.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            <Checkbox defaultChecked onChange={() => setPark(!park)} />
            <Link to="#parkingeventsites" smooth>
              Parking Events Summary
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
            Table 1 – Unofficial Parking Rate Percent: This table provides a
            summary of the parking events for the selected year and month by
            parking duration. This includes the unofficial parking rate, which
            is an indicator of how much parking is occurring outside of the
            official parking spaces.
          <br></br>
            Table 2 – Average Daily Parking Usage: This table provides a summary
            of the average daily parking usage, including an expansion factor.
        </AccordionDetails>
      </Accordion>

      {parkingSites && parkingSites
        .filter((itm) => itm.name === query.selectedSite.selected)[0]
        .dir.map((obj) => {
          console.log("dashboard", query.selectedSite.selected, ":", obj);
          return (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onDoubleClick={handleToggle(obj, "hour")}
                  />
                  <Link to={"# " + obj + "hour"} smooth>
                    Utilization by Hour -
                    {setChartTitle(
                      query.selectedSite.year,
                      query.selectedSite.selected,
                      obj
                    )}
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                  These figures provide a summary of the parking events by hour
                  for each month included in the data, one for those parking
                  events in authorized parking locations and one for those
                  parking events in unauthorized parking locations. The next set
                  provide the same information except for the user selected
                  parking duration (selection above the figures).
              </AccordionDetails>
            </Accordion>
          );
        })}

      {parkingSites.map((itm1) => {
        return itm1.name === query.selectedSite.selected &&
          itm1.type === "Public" ? (
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>
                  <Checkbox
                    defaultChecked
                    onDoubleClick={handleToggle(itm1.dir[0], "day")}
                  />
                  <Link to={"#" + itm1.dir[0] + "day"} smooth>
                    Utilization by Day -
                    {setChartTitle(
                      query.selectedSite.year,
                      query.selectedSite.selected,
                      itm1.dir[0]
                    )}
                  </Link>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                  The first set of figures provide a summary of the parking
                  events by day for each month included in the data, one for
                  those parking events in authorized parking locations and one
                  for those parking events in unauthorized parking locations.
                  The next set provide the same information except for the user
                  selected parking duration (selection above the figures).
              </AccordionDetails>
            </Accordion>
            {itm1.dir[1] != null && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>
                    <Checkbox
                      defaultChecked
                      onDoubleClick={handleToggle(itm1.dir[1], "day")}
                    />
                    <Link to={"#" + itm1.dir[1] + "day"} smooth>
                      Utilization by Day -
                      {setChartTitle(
                        query.selectedSite.year,
                        query.selectedSite.selected,
                        itm1.dir[1]
                      )}
                    </Link>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    The first set of figures provide a summary of the parking
                    events by day for each month included in the data, one for
                    those parking events in authorized parking locations and one
                    for those parking events in unauthorized parking locations.
                    The next set provide the same information except for the
                    user selected parking duration (selection above the
                    figures).
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        ) : null;
      })}
    </div>
  );
}
export default Dashboard;
