import axios from "axios";

export const fetchEventCountsByMonthParkingCat = async (
  region,
  year,
  month,
  dayTypes,
  vehWeightClasses
) => {
  // console.log("fetchEventsH3Summary-api-call");
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchEventCountsByMonthParkingCat?region=${region}&year=${year}&month=${month}&daytype=${dayTypes}&vehweightclass=${vehWeightClasses}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchEventsH3Summary = async (
  region,
  year,
  month,
  resolution,
  parkingcats,
  vehWeightClasses,
  dayTypes
) => {
  // console.log("fetchEventsH3Summary-api-call");
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchEventsH3Summary?region=${region}&year=${year}&month=${month}&resolution=${resolution}&parkingcats=${parkingcats}&vehweightclasses=${vehWeightClasses}&daytypes=${dayTypes}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchEventsSummaryByH3 = async (
  region,
  year,
  month,
  resolution
) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchEventsSummaryByH3Resolution?region=${region}&year=${year}&month=${month}&resolution=${resolution}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchEventsByH3 = async (year, month, resolution, hexids) => {
  try {
    // const dataUrl = `${process.env.REACT_APP_API_ROOT}/truckParking/findByRegion?region=statewide`;
    // const dataUrl =
    //   `${process.env.REACT_APP_API_ROOT}` +
    //   `/truckParking/findByRegion?region=${region}&year=${year}&month=${month}`;
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchEventsByH3?year=${year}&month=${month}&resolution=${resolution}&hexids=${hexids}`;
    // console.log("fetchEventsByH3-dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchEventCountsByMonthHour = async (
  region,
  year,
  month,
  dayTypes,
  vehWeightClasses
) => {
  // console.log("fetchEventsH3Summary-api-call");
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchEventCountsByMonthHour?region=${region}&year=${year}&month=${month}&daytype=${dayTypes}&vehweightclass=${vehWeightClasses}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchParkingSitesGeom = async (siteName) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findParkingSitesGeom?parkingSiteName=${siteName}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    // console.log("failed stuff");

    return false;
  }
};

export const findParkingSiteEvents = async (siteName, year, month) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findParkingSiteEvents?parkingSiteName=${siteName}&year=${year}&month=${month}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findSiteUnoffRatioByDirYearMonthDay = async (
  siteName,
  direction,
  year,
  month
) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findSiteUnoffRatioByDirYearMonthDay?parkingSiteName=${siteName}&direction=${direction}&year=${year}&month=${month}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findSiteOffUnoffByYearMonthDay = async (
  siteName,
  // direction,
  year
) => {
  try {
    // let dataUrl =
    //   `${process.env.REACT_APP_API_ROOT}` +
    //   `/truckParking/findSiteOffUnoffByYearMonthDay?parkingSiteName=${siteName}&direction=${direction}&year=${year}`;
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findSiteOffUnoffByYearMonthDay?parkingSiteName=${siteName}&year=${year}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findSiteOffUnoffByYearMonthHour = async (siteName, year) => {
  try {
    // let dataUrl =
    //   `${process.env.REACT_APP_API_ROOT}` +
    //   `/truckParking/findSiteOffUnoffByYearMonthHour?parkingSiteName=${siteName}&direction=${direction}&year=${year}`;
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/findSiteOffUnoffByYearMonthHour?parkingSiteName=${siteName}&year=${year}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchExpansionFactorsSiteYearMonthy = async (
  siteName,
  // direction,
  year,
  month
) => {
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchExpansionFactorsSiteYearMonthy?parkingSiteName=${siteName}&year=${year}&month=${month}`;
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchParkingEventsHourlyStats = async (
  year,
  month,
  dayTypes,
  vehWeightClasses
) => {
  // console.log("fetchEventsH3Summary-api-call");
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_ROOT}` +
      `/truckParking/fetchParkingEventsHourlyStats?year=${year}&month=${month}&daytype=${dayTypes}&vehweightclass=${vehWeightClasses}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchStatewideTruckParkingLocations = async () => {
  // console.log("fetchEventsH3Summary-api-call");
  // let counties =
  // "182,82,18,85,221,243,101,227,37,235,20,50,141,246,11,204,161,7,30,226,39,181,71,16,117,131";
  let counties =
    "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25," +
    "26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50," +
    "51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75," +
    "76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100," +
    "101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125," +
    "126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150," +
    "151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175," +
    "176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200," +
    "201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225," +
    "226,227,228,229,230,231,232,233,234,235,236,237,238,239,240,241,242,243,244,245,246,247,248,249,250," +
    "251,252,253,254";
  try {
    let dataUrl =
      `${process.env.REACT_APP_API_TCAT}` +
      `/poi/getTruckParkingLocations?counties=${counties}`;
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchCommercialBorderCrossings = async () => {
  // console.log("fetchEventsH3Summary-api-call");

  try {
    const dataUrl =
      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Border_Crossings/FeatureServer/9/query?where=VEH_COML%20=%201&outFields=*&outSR=4326&f=geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchRailBorderCrossings = async () => {
  // console.log("fetchEventsH3Summary-api-call");

  try {
    const dataUrl =
      "https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Border_Crossings/FeatureServer/9/query?where=RR_BRDGE%20=%201&outFields=*&outSR=4326&f=geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchCargoAirports = async () => {
  // console.log("fetchEventsH3Summary-api-call");

  try {
    const dataUrl =
      "https://geo.dot.gov/mapping/rest/services/NTAD/Aviation_Facilities/MapServer/0/query?where=OTHER_SERVICES+like+%27%25CARGO%25%27+and+STATE_CODE+%3D+%27TX%27&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const fetchPorts = async () => {
  // console.log("fetchEventsH3Summary-api-call");

  try {
    const dataUrl =
      "https://opendata.arcgis.com/datasets/595556c111e14519be70b955e10aa8ff_0.geojson";
    // console.log("dataUrl-check", dataUrl);
    const response = await axios.get(dataUrl);
    if (response.status === 200) {
      // response - object, eg { status: 200, message: 'OK' }
      // console.log("success stuff", response.data);
      return response;
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};
