import React, { useState, useEffect, useContext, useMemo } from "react";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { H3HexagonLayer } from "@deck.gl/geo-layers";

import {
  StaticMap,
  MapContext,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";

import { yearsMonths, geographies } from "../common/constants";

import { makeStyles } from "@material-ui/core";

import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

import {
  fetchEventsByH3,
  fetchEventsH3Summary,
  fetchStatewideTruckParkingLocations,
  fetchCommercialBorderCrossings,
  fetchRailBorderCrossings,
  fetchCargoAirports,
  fetchPorts,
} from "../data-loaders/DataLoaders";

import { QueryContext } from "../context/QueryContext";

import {
  regionParkingDataTypes,
  vehicleWeightClassOptions,
} from "../common/constants";

import LayerControls from "./contols/LayerControls";

import StateMapLegend from "./StateMapLegend";
import StateYrMnthSelect from "./StateYrMnthSelect";

import Grid from "@material-ui/core/Grid";

import { polyfill, kRing } from "h3-js";

import { scaleThreshold } from "d3-scale";
import { color } from "d3-color";

import truckParkingIconSvg from "../assets/icons/local_parking_white_on_green_24dp.svg";
import commercialBorderCrossingIconSvg from "../assets/icons/green_box_24dp.svg";
import railBorderCrossingIconSvg from "../assets/icons/pink_box_24dp.svg";
import cargoAirportsIconSvg from "../assets/icons/flight_yellow_24dp.svg";
import portsIconSvg from "../assets/icons/anchor_white_24dp.svg";

const useStyles = makeStyles({
  deckglMap: {
    position: "relative",
  },
  dialog: {
    position: "absolute",
    left: "92%",
    top: "50%",
    transform: "translate(-75%,-50%)",
  },
  dialog2: {
    position: "absolute",
    left: "69vw",
    top: "27vh",
  },
  // dataParameters: {
  //   position: "absolute",
  //   left: "94%",
  //   top: "35%",
  //   transform: "translate(-75%,-50%)",
  //   color: "black",
  // },
  dataParameters: {
    // position: "relative",
    // left: "1%",
    // top: "21%",
    // // transform: "translate(-75%,-50%)",
    position: "absolute",
    marginLeft: "12px",
    marginTop: "150px",
    color: "black",
  },
  dataParameters2: {
    // position: "relative",
    // left: "1%",
    // top: "21%",
    // // transform: "translate(-75%,-50%)",
    position: "absolute",
    // marginLeft: "78%",
    left: "74%",
    // marginTop: "150px",
    top: "1%",
    color: "black",
  },
  boxBackground: {
    // backgroundColor: "white",
    color: "blue",
    fontSize: 12,
    position: "absolute",
  },
});

function DeckglMapH3(props) {
  const classes = useStyles();
  const { regionSelected, setRegionSelected} = props;
  
  /*loading overlay status to display spinner*/
  const [isLoading, setIsLoading] = useState({
    current: 0,
    total: 0,
    progress: 0,
  });
  // console.log("statewideData-check", statewideData);
  const [statewideData, setStatewideData] = useState(null);

  const [query, dispatch] = useContext(QueryContext);
  const [viewport, setViewport] = useState({
    // height: "500px",
    //height: "65vh",
    // height: "65%",
    // width: "100%",
    //initViewport
    width: "100%",
    height: "100%",
    longitude: -77.6413,
    latitude: 39.0458,
    zoom: 7,
    pitch: 52,
    bearing: 0,
  });

  const NAV_CONTROL_STYLE = {
    position: "absolute",
    // top: "5px",
    // left: "5px",
    marginTop: "10px",
    marginLeft: "12px",
  };
  const ScaleControl_STYLE = {
    position: "absolute",
    top: "96%",
    left: "125px",
  };
  const layers_CONTROL_STYLE = {
    position: "absolute",
    // top: "90px",
    // left: 0,
    marginTop: "90px",
    marginLeft: "0px",
    paddingLeft: "0px",
  };

  const [homeMapBounds, setHomeMapBounds] = useState({
    // longitude: -99.458333,
    // latitude: 31.243333,
    longitude: -99.75,
    latitude: 29.3,
    zoom: 5.75,
  });

  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");

  const [zoomLevel, setZoomLevel] = useState(5.75);

  const [showRegionParking, setShowRegionParking] = useState(true);
  const dayTypeOptions = [
    { label: "All Daytypes", value: 10 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
    { label: "Sunday", value: 7 },
  ];

  function getFilteredStatewideData() {
    let statewideDataTemp = statewideData;
    // let selectedVehicleWeightClassTemp = parseInt(
    //   query.selectedVehicleWeightClass
    // );
    let selectedVehicleWeightClassTemp = query.selectedVehicleWeightClass;
    if (selectedVehicleWeightClassTemp !== 0) {
      statewideDataTemp = statewideDataTemp.filter(
        (filter) =>
          // filter.dow_desc === selectedDayType &&
          filter.vehicleweightclass === selectedVehicleWeightClassTemp
        // vehicleWeightClassOptions[selectedVehicleWeightClass].value
      );
    }
    if (query.selectedDayType !== "All Daytypes") {
      statewideDataTemp = statewideDataTemp.filter(
        (filter) =>
          // filter.dow_desc === selectedDayType &&
          filter.dow_desc === query.selectedDayType
        // vehicleWeightClassOptions[selectedVehicleWeightClass].value
      );
    }
    // if (regionSelected.regionParkingDataType === 0) return statewideDataTemp;
    // let tempData = statewideDataTemp.filter(
    //   (f) => f.parking_cat === regionSelected.regionParkingDataType
    // );
    let selectCats = regionSelected.parkingDurationCategories;
    let tempData = statewideDataTemp;
    if (tempData)
      // console.log("selectCats-check", selectCats);
      tempData = tempData.filter((obj) => selectCats.includes(obj.parking_cat));
    return tempData;
  }

  function deckGlTooltip(layer, object) {
    // console.log("object-check", object);
    let toolTipStyle = {
      backgroundColor: "#e1e7ed",
      color: "#000000",
      fontSize: "1.0em",
    };
    if (layer.id === "statewideData-grid") {
      // let selectedVehicleWeightClassTemp = parseInt(
      //   query.selectedVehicleWeightClass
      // );
      let selectedVehicleWeightClassTemp = query.selectedVehicleWeightClass;

      let vehWeightClassLabel = vehicleWeightClassOptions.filter(
        (obj) => obj.value === selectedVehicleWeightClassTemp
      )[0].label;
      return {
        html:
          `<h7><strong>Cluster of Parking Events</strong></h7>` +
          `<br />` +
          `<h7>Count: </h7> ${object.sum.toLocaleString()}` +
          `<br />` +
          regionSelected.selected +
          `<br />` +
          // regionSelected.year +
          // `-` +
          // regionSelected.month +
          yearsMonths.filter(
            (itm) =>
              itm.year === query.statewide.year &&
              itm.month === query.statewide.month
          )[0].yearMonth +
          `<br />` +
          regionParkingDataTypes[regionSelected.regionParkingDataType].label +
          `<br />` +
          vehWeightClassLabel +
          `<br />` +
          query.selectedDayType,
        style: toolTipStyle,
      };
    }

    if (layer.id === "statewideData") {
      let vehWeightClassLabel = vehicleWeightClassOptions.filter(
        (obj) => obj.value === object.vehicleweightclass
      )[0].label;
      return {
        html:
          `<h7><strong>Parking Event</strong></h7>` +
          `<br />` +
          `<h7>Parking Duration: </h7> ${object.minutes.toLocaleString()} minutes` +
          `<br />` +
          `<h7>Parking Duration Category: </h7> ${
            regionParkingDataTypes[regionSelected.regionParkingDataType].label
          } minutes` +
          `<br />` +
          query.statewide.year +
          `-` +
          query.statewide.month +
          `<br />` +
          vehWeightClassLabel +
          `<br />` +
          `${object.dow_desc}`,
        style: toolTipStyle,
      };
    }

    if (layer.id === "state-parking-sites")
      return {
        html: `<h7>Truck Parking Location</h7>`,
        style: toolTipStyle,
      };

    if (
      layer.id === "commercial-border-crossings" ||
      layer.id === "rail-border-crossings"
    ) {
      let borderCrossingCategory = "Commercial";
      if (layer.id === "railBorderCrossings") borderCrossingCategory = "Rail";
      let commercialAccess = object.properties.VEH_COML === 1 ? "Yes" : "No";
      let noncommercialAccess = object.properties.VEH_CAR === 1 ? "Yes" : "No";
      let railAccess = object.properties.RR_BRDGE === 1 ? "Yes" : "No";
      let pedestrianAccess = object.properties.PED === 1 ? "Yes" : "No";

      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Border Crossings - ${borderCrossingCategory} Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${object.properties.CROSS_NM}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Type:</strong> ${object.properties.CRS_TYPE}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Owner:</strong> ${object.properties.OWNER}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Operator:</strong> ${object.properties.OPRTR}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Jurisdiction Type:</strong> ${object.properties.JURIS_TYPE}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commercial:</strong> ${commercialAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Non-Commercial:</strong> ${noncommercialAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Rail:</strong> ${railAccess}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Pedestrian:</strong> ${pedestrianAccess}</p>
    </div>`,
        style: toolTipStyle,
      };
    }

    if (layer.id === "cargo-airports" || layer.id === "airports") {
      let airportCategory = "Cargo Airports";
      if (layer.id === "airports")
        airportCategory = "Other Major Airports Airports";
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">${airportCategory} Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Airport Name:</strong> ${object.properties.ARPT_NAME}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Location:</strong> ${object.properties.CITY}, TX</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commercial Ops:</strong> ${object.properties.COMMERCIAL_OPS}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Commuter Ops:</strong> ${object.properties.AIR_TAXI_OPS}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Other Services:</strong> ${object.properties.OTHER_SERVICES}</p>
    </div>`,
        style: toolTipStyle,
      };
    }

    if (layer.id === "ports")
      return {
        html: `<div>
      <div style="text-align: center;border-bottom: 2.0px solid black;">
        <h5 style="margin-bottom: 0.25rem;margin-top: 0.25rem;">Ports Layer</h5>
      </div>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Name:</strong> ${object.properties.PORT_NM}</p>
      <p style="margin-bottom: 0.25rem;margin-top: 0.25rem;"><strong>Type:</strong> ${object.properties.PORT_TYPE}</p>
    </div>`,
        style: toolTipStyle,
      };

    return;
  }

  // console.log("isLoading-check", isLoading);

  const [showStateParkingSites, setShowStateParkingSites] = useState(false);
  const [showCommercialBorderCrossings, setShowCommercialBorderCrossings] =
    useState(false);
  const [showRailBorderCrossings, setShowRailBorderCrossings] = useState(false);
  const [showCargoAirports, setShowCargoAirports] = useState(false);
  const [showPorts, setShowPorts] = useState(false);
  const dataLayers = [
    {
      label: "Region Parking Data",
      key: "region-parking-data",
      visibility: showRegionParking,
      setVisibility: setShowRegionParking,
      icon: null,
    },
    {
      label: "Statewide Parking Sites",
      key: "state-parking-sites",
      visibility: showStateParkingSites,
      setVisibility: setShowStateParkingSites,
      icon: truckParkingIconSvg,
    },
    {
      label: "Commercial Border Crossings",
      key: "commercial-border-crossings",
      visibility: showCommercialBorderCrossings,
      setVisibility: setShowCommercialBorderCrossings,
      icon: commercialBorderCrossingIconSvg,
    },
    {
      label: "Rail Border Crossings",
      key: "rail-border-crossings",
      visibility: showRailBorderCrossings,
      setVisibility: setShowRailBorderCrossings,
      icon: railBorderCrossingIconSvg,
    },
    {
      label: "Cargo Airports",
      key: "cargo-airports",
      visibility: showCargoAirports,
      setVisibility: setShowCargoAirports,
      icon: cargoAirportsIconSvg,
    },
    {
      label: "Ports",
      key: "ports",
      visibility: showPorts,
      setVisibility: setShowPorts,
      icon: portsIconSvg,
    },
  ];
  const [portsData, setPortsData] = useState(null);
  useMemo(() => {
    if (showPorts && portsData == null) {
      const fetchPortsData = async () => {
        await fetchPorts().then((res) => {
          // console.log("res-check", res);
          setPortsData(res.data);
        });
      };
      fetchPortsData();
    }
  }, [portsData, showPorts]);
  const [cargoAirportsData, setCargoAirportsData] = useState(null);
  useMemo(() => {
    if (showCargoAirports && cargoAirportsData == null) {
      const fetchCargoAirportsData = async () => {
        await fetchCargoAirports().then((res) => {
          // console.log("res-check", res);
          setCargoAirportsData(res.data);
        });
      };
      fetchCargoAirportsData();
    }
  }, [cargoAirportsData, showCargoAirports]);
  const [railBorderCrossingsData, setRailBorderCrossingsData] = useState(null);
  useMemo(() => {
    if (showRailBorderCrossings && railBorderCrossingsData == null) {
      const fetchRailBorderCrossingsData = async () => {
        await fetchRailBorderCrossings().then((res) => {
          // console.log("res-check", res);
          setRailBorderCrossingsData(res.data);
        });
      };
      fetchRailBorderCrossingsData();
    }
  }, [railBorderCrossingsData, showRailBorderCrossings]);
  const [commercialBorderCrossingsData, setCommercialBorderCrossingsData] =
    useState(null);
  useMemo(() => {
    if (
      showCommercialBorderCrossings &&
      commercialBorderCrossingsData == null
    ) {
      const fetchCommercialBorderCrossingsData = async () => {
        await fetchCommercialBorderCrossings().then((res) => {
          // console.log("res-check", res);
          setCommercialBorderCrossingsData(res.data);
        });
      };
      fetchCommercialBorderCrossingsData();
    }
  }, [commercialBorderCrossingsData, showCommercialBorderCrossings]);
  const [stateParkingSitesData, setStateParkingSitesData] = useState(null);
  useMemo(() => {
    if (showStateParkingSites && stateParkingSitesData == null) {
      const fetchStateParkingSitesData = async () => {
        await fetchStatewideTruckParkingLocations().then((res) => {
          // console.log("res-check", res);
          setStateParkingSitesData(res.data);
        });
      };
      fetchStateParkingSitesData();
    }
  }, [showStateParkingSites]);

  const [statewideH3Data, setStatewideH3Data] = useState([]);
  const [h3Resolution, setH3Resolution] = useState(6);

  useMemo(() => {
    let regionLookup = geographies.filter(
      (itm) => itm.name === regionSelected.selected
    )[0].lookup;
    if (regionLookup) {
      setIsLoading({
        current: 1,
        total: 2,
        progress: 50,
      });
    }
    // console.log("regionSelected-check", regionSelected);

    const fetchTParkingDataH3 = async () => {
      let vehWeightClasses = [2, 3];
      if (query.selectedVehicleWeightClass != 0)
        vehWeightClasses = query.selectedVehicleWeightClass;
      let dayTypes = [1, 2, 3, 4, 5, 6, 7];
      if (query.selectedDayType != "All Daytypes")
        dayTypes = dayTypeOptions.filter(
          (itm) => itm.label === query.selectedDayType
        )[0].value;

      await fetchEventsH3Summary(
        regionLookup,
        query.statewide.year,
        query.statewide.month,
        query.stateMapGridResolution,
        regionSelected.parkingDurationCategories,
        vehWeightClasses,
        dayTypes
        // 2020,
        // 3,
        // 8
        // h3Resolution
      ).then((res) => {
        setStatewideH3Data(res.data);

        setIsLoading({
          current: 2,
          total: 2,
          progress: 100,
        });
      });
    };
    fetchTParkingDataH3();
  }, [
    query.selectedVehicleWeightClass,
    query.selectedDayType,
    query.statewide.year,
    regionSelected.geographyOptionsIndex,
    // regionSelected.year,
    query.statewide.month,
    // regionSelected.parkingDurationCategories,
    query.stateMapGridResolution,
  ]);
  const [scatterPlotH3, setScatterPlotH3] = useState([]);

  const [prevZoom, setPrevZoom] = useState(5);

  const [controllerAvail, setControllerAvail] = useState(false);
  useEffect(() => {
    if (isLoading.current !== isLoading.total) setControllerAvail(false);
    else setControllerAvail(true);
  }, [isLoading]);

  function setParkingEventsByH3(projection) {
    const [west, north] = projection.unproject([0, 0]);
    const [east, south] = projection.unproject([
      projection.width,
      projection.height,
    ]);
    // console.log("bbox-check", west, north, east, south);
    const nw = [north, west];
    const ne = [north, east];
    const sw = [south, west];
    const se = [south, east];
    // console.log("bbox-check", nw, ne, sw, se);
    const hexes = polyfill([nw, ne, se, sw], 6);
    // console.log("hexes-check", hexes);

    let hexesBuffered = hexes;

    hexes.map((itm) => {
      let buffers = kRing(itm, 3);
      // hexesBuffered.push(buffers);
      // hexesBuffered.push(itm);
      // console.log("buffers-test", hexes, itm);

      buffers.map((itm) => {
        // console.log(
        //   "hexesBuffered-index-check",
        //   itm,
        //   hexesBuffered.indexOf(itm)
        // );
        if (hexesBuffered.indexOf(itm) === -1) hexesBuffered.push(itm);
      });
    });

    // console.log("scatterPlotH3-check", scatterPlotH3);
    let newHexes = [];
    hexesBuffered.map((itm) => {
      if (scatterPlotH3.indexOf(itm) === -1) {
        // console.log(
        //   "newHexes-index-check",
        //   itm,
        //   scatterPlotH3.indexOf(itm)
        // );
        newHexes.push(itm);
      }
    });
    // console.log("newHexes-check", newHexes, scatterPlotH3);
    // console.log("newHexes.length-check", newHexes.length);
    if (
      // hexesBuffered.length === scatterPlotH3.length &&
      newHexes.length > 0
    ) {
      setIsLoading({
        current: 1,
        total: 2,
        progress: 50,
      });

      let tempHexes = scatterPlotH3;
      newHexes.map((itm) => tempHexes.push(itm));
      setScatterPlotH3(tempHexes);
      // scatterPlotH3 = newHexes;
      // console.log("newHexes-check", newHexes);
      const fetchTParkingEventsByH3 = async () => {
        await fetchEventsByH3(
          query.statewide.year,
          query.statewide.month,
          6,
          newHexes
        ).then((res) => {
          // console.log("fetch H3 check, zoomLevel=", zoomLevel.current, h3Resolution);
          if (statewideData === null) setStatewideData(res.data);
          else {
            let statewideDataTemp = statewideData;
            // res.data.map((itm) => statewideDataTemp.push(itm));
            setStatewideData([...statewideData, ...res.data]);
          }
          // console.log(
          //   "scatterPlotH3-check-good-else",
          //   hexesBuffered,
          //   scatterPlotH3,
          //   res.data
          // );
          setIsLoading({
            current: 2,
            total: 2,
            progress: 100,
          });
        });
      };
      fetchTParkingEventsByH3();
    }
  }

  const handleViewStateChange = ( newViewState ) => {
    if ( newViewState.viewState.zoom !== zoomLevel) {
      //setPrevZoom(zoomLevel);
      setZoomLevel(newViewState.viewState.zoom);
      // let resolutionCalc = Math.min(
      //   10,
      //   Math.max(6, Math.round(viewState.zoom, 0))
      // );

      // setH3Resolution(resolutionCalc);

      // if (viewState.zoom >= 11) {

      //   const projection = new WebMercatorViewport(viewState);

      //   setParkingEventsByH3(projection);
      // }
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        height: "90%",
        width: "66%",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <DeckGL
        {...viewport}
        // initialViewState={initialViewState}
        initialViewState={{
          longitude: homeMapBounds.longitude,
          latitude: homeMapBounds.latitude,
          zoom: homeMapBounds.zoom,
          pitch: 52,
          bearing: 0,
          transitionDuration: 0,

          transitionEasing: "Ease-In-Sine",
        }}
        getTooltip={({ layer, object }) =>
          object && deckGlTooltip(layer, object)
        }
        controller={controllerAvail}
        // controller={true}
        ContextProvider={MapContext.Provider}
        onViewStateChange={handleViewStateChange}

        // onDrag={(info, event) => {

        //   if (zoomLevel >= 11) {
        //     const projection = info.viewport;

        //     setParkingEventsByH3(projection);
        //   }
        // }}
      >
        <StaticMap
          // reuseMaps
          mapStyle={baseMap}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
          preventStyleDiffing={true}
        />
        {isLoading.current !== isLoading.total && (
          <Box
            sx={{ marginTop: "43vh", marginLeft: "50%" }}
            className={classes.boxBackground}
          >
            <CircularProgress
              style={{
                marginLeft: "5%",
                marginTop: "5%",
              }}
              size={50}
              thickness={5}
            />
          </Box>
        )}
        {/* {statewideData !== null && showRegionParking && zoomLevel >= 11 && (
          <ScatterplotLayer
            id="statewideData"
            data={getFilteredStatewideData()}
            getPosition={(d) => [d.long, d.lat]}
            pickable={true}
            opacity={0.8}
            filled={true}
            radiusScale={1}
            radiusMaxPixels={5}
            getRadius={300}
            getFillColor={(d) => {
              let colors = [
                "#7F3C8D",
                "#11A579",
                "#3969AC",
                "#F2B701",
                "#E73F74",
                "#80BA5A",
                "#E68310",
                "#008695",
                "#CF1C90",
                "#f97b72",
                "#4b4b8f",
                "#A5AA99",
              ];

              let colorTemp = colors[d.parking_cat - 1];
              let colorTempRGB = hexToRgb(colorTemp);
              return [colorTempRGB.r, colorTempRGB.g, colorTempRGB.b];
            }}
            getLineColor={(d) => [0, 0, 0]}
          />
        )} */}
        {statewideH3Data !== null && showRegionParking && (
          // {statewideH3Data !== null && showRegionParking && zoomLevel < 11 && (
          <H3HexagonLayer
            id="statewideData-grid"
            data={statewideH3Data}
            pickable={true}
            wireframe={false}
            filled={true}
            extruded={true}
            getHexagon={(d) => d.h3index}
            getFillColor={(d) => {
              const threshold = scaleThreshold()
                .domain([10, 100, 250, 500, 1000, 1500, 2000])
                .range([
                  "#e6ddc9",
                  "#e5c99c",
                  "#e8b373",
                  "#ec9a4d",
                  "#f17d2c",
                  "#f7580f",
                  "#fd0202",
                ]);

              const colorFill = color(threshold(d.sum));
              return [colorFill.r, colorFill.g, colorFill.b];
            }}
            getElevation={(d) => d.sum * (10 / zoomLevel)}
            // opacity={0.5}
            opacity={query.mapOpacity}
            updateTriggers={{
              // This tells deck.gl to recalculate radius when `currentYear` changes
              getElevation: zoomLevel,
            }}
          />
        )}
        {stateParkingSitesData !== null && showStateParkingSites && (
          <GeoJsonLayer
            id="state-parking-sites"
            data={stateParkingSitesData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: truckParkingIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {commercialBorderCrossingsData !== null &&
          showCommercialBorderCrossings && (
            <GeoJsonLayer
              id="commercial-border-crossings"
              data={commercialBorderCrossingsData}
              pickable={true}
              wireframe={false}
              pointType={"icon"}
              getIcon={() => ({
                url: commercialBorderCrossingIconSvg,
                width: 24,
                height: 24,
              })}
              getIconSize={1}
              getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
              getIconAngle={0}
              getIconPixelOffset={[0, 0]}
              iconSizeUnits={"pixels"}
              iconSizeScale={15}
            />
          )}
        {railBorderCrossingsData !== null && showRailBorderCrossings && (
          <GeoJsonLayer
            id="rail-border-crossings"
            data={railBorderCrossingsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: railBorderCrossingIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {cargoAirportsData !== null && showCargoAirports && (
          <GeoJsonLayer
            id="cargo-airports"
            data={cargoAirportsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: cargoAirportsIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {portsData !== null && showPorts && (
          <GeoJsonLayer
            id="ports"
            data={portsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: portsIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}

        <NavigationControl style={NAV_CONTROL_STYLE} captureScroll={true} />

        <div style={layers_CONTROL_STYLE}>
          <LayerControls
            baseMap={baseMap}
            setBaseMap={setBaseMap}
            dataLayers={dataLayers}
          />
        </div>

        <StateMapLegend
          regionSelected={regionSelected}
          setRegionSelected={setRegionSelected}
          zoomLevel={zoomLevel}
        />

        <ScaleControl style={ScaleControl_STYLE} />
      </DeckGL>
    </div>
  );
}

export default DeckglMapH3;
