import React, { useState, useContext, useEffect } from "react";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";

import { QueryContext } from "../context/QueryContext";

// const useStyles = makeStyles((theme) => ({
//     root: {
//       backgroundColor: theme.palette.background.paper,
//       width: "50vw",
//       height: "25vh"
//     },
//   }));
import { gridStyles } from "../ui/styles";
import { FunctionsTwoTone } from "@mui/icons-material";

import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

export default function TablePanelSites(props) {
  // const classes = useStyles();
  const classesGrid = gridStyles();

  const [query, dispatch] = useContext(QueryContext);

  const {
    titleDesc,
    unauthorizedPercentTable,
    spaceRatioData,
    expansionFactor,
  } = props;

  const [tableData, setTableData] = useState(query.regionCategoryTableData);
  useEffect(() => {
    setTableData(query.regionCategoryTableData);
  }, [query.regionCategoryTableData]);
  // console.log("tableDataSites-check", tableData);
  // console.log("spaceRatioData-check", spaceRatioData);

  function setSpaceRatioTableData(srData, expFact) {
    // console.log("expFactData-check", expFact);
    let tempTableData = [];
    srData.map((obj) =>
      tempTableData.push({
        ...obj,
        expansion_fact: expFact,
      })
    );
    // console.log("tempTableData-check", tempTableData);
    return tempTableData;
  }
  // if (spaceRatioData && expansionFactor)
  //   setSpaceRatioTableData(spaceRatioData, expansionFactor);

  return (
    <div id="parkingeventsites" style={{ display: "flex", marginTop: 10 }}>
      <Grid container spacing={0} id={"sites-hour"}>
        <Grid item xs={12}>
          <Divider
            component="div"
            role="presentation"
            textAlign="center"
            id="sites_park_summary"
          >
            <Chip
              label={
                <Typography
                  variant="h5"
                  // component="div"
                  // sx={{ padding: "5px", textAlign: "center" }}
                  sx={{ margin: 5, padding: 5 }}
                >
                  Parking Events Summary
                </Typography>
              }
              sx={{ backgroundColor: "#a9a9a9" }}
            />
          </Divider>
        </Grid>
        <Grid item xs={7} style={{ paddingTop: 10 }}>
          <div className="table-container"
            style={{
              marginBottom: 1,
              paddingBottom: 1,
              marginLeft: 0,
              paddingLeft: 0,
            }}
          >
            {unauthorizedPercentTable && (
              <MaterialTable
                columns={[
                  {
                    title: "Site Name",
                    field: "siteName",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Year-Month",
                    field: "yearMonth",
                    width: "5%",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Parking Category",
                    field: "parkingCategory",
                    // type: "numeric",
                    // hidden: true,
                    // export: true,
                    cellStyle: {
                      backgroundColor: "#039be5",
                      color: "#FFF",
                      // width: "100px",
                      // maxWidth: "2px",
                    },
                  },
                  {
                    title: "Total Count",
                    field: "totalCount",
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.totalCount.toLocaleString(),
                  },
                  {
                    title: "Official Location Count",
                    field: "authorizedCount",
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.authorizedCount.toLocaleString(),
                  },
                  {
                    title: "Unofficial Location Count",
                    field: "unauthorizedCount",
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.unauthorizedCount.toLocaleString(),
                  },
                  {
                    title: "Official Rate (%)",
                    field: "percentAuthorized",
                    type: "numeric",
                    render: (obj) => obj.percentAuthorized.toFixed(1),
                  },
                  {
                    title: "Unofficial Rate (%)",
                    field: "percentUnauthorized",
                    type: "numeric",
                    render: (obj) => obj.percentUnauthorized.toFixed(1),
                  },
                ]}
                data={unauthorizedPercentTable}
                title={
                  "Parking Events - " +
                  unauthorizedPercentTable[0].siteName +
                  " - " +
                  unauthorizedPercentTable[0].yearMonth
                }
                //   detailPanel={rowData => {
                //     return (
                //       <div>
                //        <PlotlyBarChart />
                //       <PlotlyStackedBarChart dataX={[1, 2, 3]} dataY1={[2, 5, 3]} dataY2={[3, 4, 4]}/>
                //       </div>
                //     )
                //   }}
                //   onRowClick={(event, rowData, togglePanel) => togglePanel()}
                options={{
                  exportButton: { csv: true, pdf: false },
                  // maxBodyHeight: "25vh"
                  exportAllData: true,
                  exportFileName:
                    unauthorizedPercentTable[0].siteName +
                    "_" +
                    unauthorizedPercentTable[0].yearMonth +
                    "_unauthorized-parking-summary",
                  paging: false,
                  rowStyle: {
                    wordWrap: "break-word",
                  },
                  padding: "dense",
                  // tableLayout: "auto",
                  doubleHorizontalScroll: false,
                  // detailPanelType: "multiple",
                  search: false,
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF",
                  },
                }}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={5} style={{ paddingLeft: 10, paddingTop: 10 }}>
          <div className="table-container">
            {spaceRatioData && (
              <MaterialTable
                columns={[
                  {
                    title: "Site Name",
                    field: "siteName",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Direction",
                    field: "dir",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Year-Month",
                    field: "yearMonth",
                    width: "5%",
                    hidden: true,
                    export: true,
                  },
                  {
                    title: "Site Name-Direction",
                    field: "siteLabel",
                    cellStyle: {
                      backgroundColor: "#039be5",
                      color: "#FFF",
                      // width: "100px",
                      // maxWidth: "2px",
                    },
                    // type: "numeric",
                    // hidden: true,
                    // export: true,
                  },
                  {
                    title: "Unexpanded Average Daily Total Count",
                    field: "totalCount",
                    type: "numeric",
                    // hidden: true,
                    // export: true,
                    render: (obj) => obj.totalCount.toLocaleString(),
                    // render: (obj) => obj.totalCount.toFixed(0),
                  },
                  // Commenteed out parking spaces and space ratio for now - no spaces data - may add back in as data allows
                  // {
                  //   title: "Parking Spaces",
                  //   field: "spaces",
                  //   type: "numeric",
                  //   // hidden: true,
                  //   // export: true,
                  //   render: (obj) => obj.spaces.toLocaleString(),
                  // },
                  // {
                  //   title: "Unexpanded Space Ratio (%)",
                  //   field: "spaceRatio",
                  //   type: "numeric",
                  //   render: (obj) => obj.spaceRatio.toFixed(2),
                  // },
                  {
                    title: "Expansion Factor",
                    field: "expansion_fact",
                    type: "numeric",
                    render: (obj) => obj.expansion_fact.toFixed(2),
                  },
                ]}
                // data={spaceRatioData}
                data={setSpaceRatioTableData(spaceRatioData, expansionFactor)}
                // title={
                //   spaceRatioData[0].siteName +
                //   " - " +
                //   spaceRatioData[0].yearMonth
                // }
                title="Expansion Factor"
                //   detailPanel={rowData => {
                //     return (
                //       <div>
                //        <PlotlyBarChart />
                //       <PlotlyStackedBarChart dataX={[1, 2, 3]} dataY1={[2, 5, 3]} dataY2={[3, 4, 4]}/>
                //       </div>
                //     )
                //   }}
                //   onRowClick={(event, rowData, togglePanel) => togglePanel()}
                options={{
                  exportButton: { csv: true, pdf: false },
                  // maxBodyHeight: "25vh"
                  exportAllData: true,
                  exportFileName:
                    spaceRatioData[0].siteName +
                    "_" +
                    spaceRatioData[0].yearMonth +
                    "_space-ratios",
                  paging: false,
                  rowStyle: {
                    wordWrap: "break-word",
                  },
                  padding: "dense",
                  // tableLayout: "auto",
                  doubleHorizontalScroll: false,
                  // detailPanelType: "multiple",
                  search: false,
                  // maxToolBarHeight: "0.01vh",
                  headerStyle: {
                    // position: "sticky",
                    // top: 0,
                    // paddingTop: 10,
                    // marginTop: 10,
                    backgroundColor: "#01579b",
                    color: "#FFF",
                  },
                }}
                // Start - below removes the title functionality - keep
                // components={{
                //   Toolbar: (props) => {
                //     // Make a copy of props so we can hide the default title and show our own
                //     // This let's us use our own Title while keeping the search
                //     const propsCopy = { ...props };
                //     // Hide default title
                //     propsCopy.showTitle = false;
                //     return (
                //       <div
                //         style={{
                //           // backgroundColor: "lightblue",
                //           height: "25px",
                //           display: "flex",
                //           alignItems: "center",
                //           // borderTop: "solid",
                //           paddingBottom: "0px",
                //           marginBottom: "0px",
                //         }}
                //       >
                //         <Grid container direction="row">
                //           {/* <Grid item xs={6}>
                //     <h2>Some Table Title</h2>
                //   </Grid> */}
                //           <Grid item xs={12}>
                //             <MTableToolbar {...propsCopy} />
                //           </Grid>
                //         </Grid>
                //       </div>
                //     );
                //   },
                // }}
                // END - below removes the title functionality - keep
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
