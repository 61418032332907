import React from "react";

import { Routes, Route } from "react-router-dom";
import SitesLayout from "./SitesLayout";
import StatewideLayoutH3 from "./StatewideLayoutH3";
import OtherResources from "./OtherResources";


const Layout = () => {
  //console.log("Layout-call-check");
  return(
    <Routes>
      <Route path="/" element={<StatewideLayoutH3 statusGuide="State"/>}></Route>
      <Route path="/parking-sites" element={<SitesLayout statusGuide="ParkingSites"/>}></Route>
      <Route
        path="/other-resources"
        element={<OtherResources statusGuide="Other-Resources"/>}
      ></Route>
    </Routes>
  );
}

export default Layout;
