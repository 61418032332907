import React, { useState, useContext, useEffect, useMemo } from "react";
import DeckGL from "@deck.gl/react";
import { ScatterplotLayer, GeoJsonLayer } from "@deck.gl/layers";
import { hexToRgb } from "../utils/utils";
// import colorCategories from "@deck.gl/carto/src/style/color-categories-style.js";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  // Popup,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";
import { makeStyles } from "@material-ui/core";
import bbox from "@turf/bbox";
import { QueryContext } from "../context/QueryContext";
import LayerControls from "./contols/LayerControls";
import Grid from "@material-ui/core/Grid";

import SiteYrMnthSelect from "./SiteYrMnthSelect";
import SiteMapLegend from "./SiteMapLegend";

import truckParkingIconSvg from "../assets/icons/local_parking_white_on_green_24dp.svg";
import commercialBorderCrossingIconSvg from "../assets/icons/green_box_24dp.svg";
import railBorderCrossingIconSvg from "../assets/icons/pink_box_24dp.svg";
import cargoAirportsIconSvg from "../assets/icons/flight_yellow_24dp.svg";
import portsIconSvg from "../assets/icons/anchor_white_24dp.svg";
import {
  fetchStatewideTruckParkingLocations,
  fetchCommercialBorderCrossings,
  fetchRailBorderCrossings,
  fetchCargoAirports,
  fetchPorts,
} from "../data-loaders/DataLoaders";

const useStyles = makeStyles({
  deckglMap: {
    position: "relative",
  },
  dialog: {
    position: "absolute",
    left: "92%",
    top: "50%",
    transform: "translate(-75%,-50%)",
  },
  dialog2: {
    position: "absolute",
    left: "69vw",
    top: "27vh",
  },
  dataParameters: {
    position: "absolute",
    left: "94%",
    top: "35%",
    transform: "translate(-75%,-50%)",
    color: "black",
  },
  boxBackground: {
    backgroundColor: "white",
    color: "blue",
    fontSize: 12,
  },
});

function DeckglMapSites(props) {
  const classes = useStyles();
  const {
    // statusGuide,
    // regionSelected,
    // setRegionSelected,
    // statewideData,
    // isLoading,
    // landUseData,
    parkingSiteGeom,
    siteEventsGeom,
    siteSelected,
  } = props;
  // console.log("statewideData-check", statewideData);
  const [query, dispatch] = useContext(QueryContext);

  const [viewport, setViewport] = useState({
    // height: "500px",
    //height: "65vh",
    // height: "65%",
    // width: "100%",
    //initViewport
    width: "100%",
    height: "100%",
  });

  const NAV_CONTROL_STYLE = {
    position: "absolute",
    // top: "5px",
    // left: "5px",
    marginTop: "10px",
    marginLeft: "12px",
  };
  const ScaleControl_STYLE = {
    position: "absolute",
    top: "96%",
    left: "125px",
  };
  const layers_CONTROL_STYLE = {
    position: "absolute",
    // top: "90px",
    // left: 0,
    marginTop: "90px",
    marginLeft: "0px",
    paddingLeft: "0px",
  };

  const [homeMapBounds, setHomeMapBounds] = useState({
    // longitude: -99.458333,
    // latitude: 31.243333,
    longitude: -76.6413,
    latitude: 39.0458,
    zoom: 7,
  });

  function getBoundsForRoads(mapBounds_bbox) {
    // Calculate corner values of bounds
    // const pointsLong = points.map((point) => point.coordinates._long);
    // const pointsLat = points.map((point) => point.coordinates._lat);
    // const cornersLongLat = [
    //   [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    //   [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    // ];
    const cornersLongLat = [
      [mapBounds_bbox[0], mapBounds_bbox[1]],
      [mapBounds_bbox[2], mapBounds_bbox[3]],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 800,
    }).fitBounds(cornersLongLat, { padding: 100 }); // Can also use option: offset: [0, -100]
    // const viewport = new WebMercatorViewport({
    //   width: 800,
    //   height: 600,
    // }).fitBounds(mapBounds_bbox, { padding: 200 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom: zoom - 0.5 };
  }

  useEffect(() => {
    if (parkingSiteGeom.features.length > 0) {
      const mapBounds_bbox = bbox(parkingSiteGeom);
      setHomeMapBounds(getBoundsForRoads(mapBounds_bbox));
    }
  }, [parkingSiteGeom]);
  
  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");

  const [zoomLevel, setZoomLevel] = useState(7);

  const handleViewStateChange = (newViewState) => {
    // Handle view state change
    if ( newViewState.viewState.zoom !== zoomLevel) {
    setZoomLevel(newViewState.viewState.zoom);
    }
  };

  const [showRegionParking, setShowRegionParking] = useState(true);

  function deckGlTooltip(layer, object) {
    // console.log("object-check", object);
    let toolTipStyle = {
      backgroundColor: "#e1e7ed",
      color: "#000000",
      fontSize: "1.0em",
    };
    if (layer.id === "parkingSiteGeom-authorized")
      return {
        html:
          `<h7><strong>Parking Area</strong></h7>` +
          `<br />` +
          `<h7>Name: </h7> ${object.properties.name}` +
          `<br />` +
          `Authorized Area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.properties.dir}` +
          `<br />` +
          `<h7>Number of spaces: </h7> ${object.properties.space}`,
        style: toolTipStyle,
      };
    // "landUse"
    if (layer.id === "parkingSiteGeom-unauthorized")
      return {
        html:
          `<h7><strong>Parking Area</strong></h7>` +
          `<br />` +
          `<h7>Name: </strong></h7> ${object.properties.name}` +
          `<br />` +
          `Unauthorized Area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.properties.dir}`,
        style: toolTipStyle,
      };
    if (layer.id === "siteEvents")
      return {
        html:
          `<h7><strong>Parking Event</strong></h7>` +
          `<br />` +
          `<h7>Site: </h7> ${object.sitename}` +
          `<br />` +
          `${object.authorized} area` +
          `<br />` +
          `<h7>Direction: </h7> ${object.dir}` +
          `<br />` +
          `<h7>Parking Duration: </h7> ${object.parking_duration}` +
          `<br />` +
          `<h7>Parked Time: </h7> ${object.total_parked_time}`,

        style: toolTipStyle,
      };

    //siteEvents

    return;
  }

  function parkingSiteGeomFilter(category) {
    if (parkingSiteGeom) {
      let filtered = parkingSiteGeom.features.filter(
        (f) => f.properties.authorized === category
      );
      //   console.log("filtered-check", filtered);
      return {
        type: "FeatureCollection",
        features: filtered,
      };
    }
  }

  const [showSiteParkingEvents, setShowSiteParkingEvents] = useState(true);
  const [showAuthorizedParkingArea, setShowAuthorizedParkingArea] =
    useState(true);
  const [showUnauthorizedParkingArea, setShowUnauthorizedParkingArea] =
    useState(true);

  const [showStateParkingSites, setShowStateParkingSites] = useState(false);
  const [showCommercialBorderCrossings, setShowCommercialBorderCrossings] =
    useState(false);
  const [showRailBorderCrossings, setShowRailBorderCrossings] = useState(false);
  const [showCargoAirports, setShowCargoAirports] = useState(false);
  const [showPorts, setShowPorts] = useState(false);
  const dataLayers = [
    {
      label: "Site Parking Events",
      key: "site-parking-events",
      visibility: showSiteParkingEvents,
      setVisibility: setShowSiteParkingEvents,
      icon: null,
    },
    {
      label: "Authorized Parking Area",
      key: "authorized-parking-area",
      visibility: showAuthorizedParkingArea,
      setVisibility: setShowAuthorizedParkingArea,
      icon: null,
    },
    {
      label: "Unauthorized Parking Area",
      key: "unauthorized-parking-area",
      visibility: showUnauthorizedParkingArea,
      setVisibility: setShowUnauthorizedParkingArea,
      icon: null,
    },
    {
      label: "Statewide Parking Sites",
      key: "state-parking-sites",
      visibility: showStateParkingSites,
      setVisibility: setShowStateParkingSites,
      icon: truckParkingIconSvg,
    },
    {
      label: "Commercial Border Crossings",
      key: "commercial-border-crossings",
      visibility: showCommercialBorderCrossings,
      setVisibility: setShowCommercialBorderCrossings,
      icon: commercialBorderCrossingIconSvg,
    },
    {
      label: "Rail Border Crossings",
      key: "rail-border-crossings",
      visibility: showRailBorderCrossings,
      setVisibility: setShowRailBorderCrossings,
      icon: railBorderCrossingIconSvg,
    },
    {
      label: "Cargo Airports",
      key: "cargo-airports",
      visibility: showCargoAirports,
      setVisibility: setShowCargoAirports,
      icon: cargoAirportsIconSvg,
    },
    {
      label: "Ports",
      key: "ports",
      visibility: showPorts,
      setVisibility: setShowPorts,
      icon: portsIconSvg,
    },
  ];
  const [portsData, setPortsData] = useState(null);
  useMemo(() => {
    if (showPorts && portsData === null) {
      const fetchPortsData = async () => {
        await fetchPorts().then((res) => {
          // console.log("res-check", res);
          setPortsData(res.data);
        });
      };
      fetchPortsData();
    }
  }, [portsData, showPorts]);
  const [cargoAirportsData, setCargoAirportsData] = useState(null);
  useMemo(() => {
    if (showCargoAirports && cargoAirportsData === null) {
      const fetchCargoAirportsData = async () => {
        await fetchCargoAirports().then((res) => {
          // console.log("res-check", res);
          setCargoAirportsData(res.data);
        });
      };
      fetchCargoAirportsData();
    }
  }, [cargoAirportsData, showCargoAirports]);
  const [railBorderCrossingsData, setRailBorderCrossingsData] = useState(null);
  useMemo(() => {
    if (showRailBorderCrossings && railBorderCrossingsData === null) {
      const fetchRailBorderCrossingsData = async () => {
        await fetchRailBorderCrossings().then((res) => {
          // console.log("res-check", res);
          setRailBorderCrossingsData(res.data);
        });
      };
      fetchRailBorderCrossingsData();
    }
  }, [railBorderCrossingsData, showRailBorderCrossings]);
  const [commercialBorderCrossingsData, setCommercialBorderCrossingsData] =
    useState(null);
  useMemo(() => {
    if ( showCommercialBorderCrossings && commercialBorderCrossingsData === null ) {
      const fetchCommercialBorderCrossingsData = async () => {
        await fetchCommercialBorderCrossings().then((res) => {
          // console.log("res-check", res);
          setCommercialBorderCrossingsData(res.data);
        });
      };
      fetchCommercialBorderCrossingsData();
    }
  }, [commercialBorderCrossingsData, showCommercialBorderCrossings]);
  const [stateParkingSitesData, setStateParkingSitesData] = useState(null);
  useMemo(() => {
    if (showStateParkingSites && stateParkingSitesData === null) {
      const fetchStateParkingSitesData = async () => {
        await fetchStatewideTruckParkingLocations().then((res) => {
          // console.log("res-check", res);
          setStateParkingSitesData(res.data);
        });
      };
      fetchStateParkingSitesData();
    }
  }, [showStateParkingSites, stateParkingSitesData]);

  const filterSiteEventsGeom = () => {
    if (siteEventsGeom) {
      let selectCats = siteSelected && query.selectedSite.parkingDurationCategories;
      // console.log("selectCats-check", selectCats);
      return siteEventsGeom.filter((obj) =>
        selectCats.includes(obj.parking_cat)
      );
    }
  };

  const [siteEventsFilteredGeom, setSiteEventsFilteredGeom] = useState(null);
  useEffect(() => {
    // console.log("selectCats-check", selectCats);
    if (siteEventsGeom){
      let selectCats = siteSelected && query.selectedSite.parkingDurationCategories;
      setSiteEventsFilteredGeom(
        siteEventsGeom.filter((obj) => selectCats.includes(obj.parking_cat))
      );
    }
    // setSiteEventsFilteredGeom(
    //   siteEventsGeom.filter(
    //     (obj) => obj.parking_cat === siteSelected.parkingDataType
    //   )
    // );
  }, [query.selectedSite.parkingDurationCategories, siteEventsGeom, siteSelected]);
  const [authorizedParkingSiteGeom, setAuthorizedParkingSiteGeom] =
    useState(null);
  const [unauthorizedParkingSiteGeom, setUnauthorizedParkingSiteGeom] =
    useState(null);
  useEffect(() => {
    if(parkingSiteGeom.features.length > 0){
      setAuthorizedParkingSiteGeom(parkingSiteGeomFilter(1));
      setUnauthorizedParkingSiteGeom(parkingSiteGeomFilter(0));
    }
    
  }, [parkingSiteGeom]);

  return (
    <div
      style={{
        position: "absolute",
        height: "90%",
        width: "58%",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <DeckGL
        {...viewport}
        // initialViewState={initialViewState}
        initialViewState={{
          longitude: homeMapBounds.longitude,
          latitude: homeMapBounds.latitude,
          zoom: homeMapBounds.zoom,
          pitch: 0,
          bearing: 0,
          transitionDuration: 300,
          // transitionInterpolator: new FlyToInterpolator(),
        }}
        getTooltip={({ layer, object }) =>
          object && deckGlTooltip(layer, object)
        }
        controller={true}
        // layers={newLayers2}
        // layers={[layers2]}
        // layers={[layers3]}
        ContextProvider={MapContext.Provider}
        onViewStateChange={handleViewStateChange}
      >
        <StaticMap
          // reuseMaps
          // mapStyle={"mapbox://styles/mapbox/streets-v11"}
          mapStyle={baseMap}
          // mapboxApiAccessToken={MAPBOX_ACCESSTOKEN}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
          preventStyleDiffing={true}
        />
        {/* {isLoading.current !== isLoading.total && (
          <Box
            sx={{ width: "90%", marginTop: "43vh", marginLeft: "5%" }}
            className={classes.boxBackground}
          >
            Loading:
            <LinearProgress variant="determinate" value={isLoading.progress} />
          </Box>
        )} */}
        {showAuthorizedParkingArea && (
          <GeoJsonLayer
            id="parkingSiteGeom-authorized"
            //   data={parkingSiteGeom}
            data={authorizedParkingSiteGeom}
            //   visible={query.contextLayersVisibility.districtBoundaries}
            filled={true}
            opacity={0.25}
            pickable={true}
            pickingRadius={5}
            stroked={true}
            //extruded={true}
            pointType={"circle"}
            lineWidthScale={5}
            lineWidthMinPixels={0.1}
            getPointRadius={0.5}
            getLineWidth={0.5}
            //getElevation={500}
            // onClick={(d, event) => {
            //   setActivePopup(true);
            //   setSelectedPoint(d);
            // }}
            // onHover={(d, event) => {
            //   if (d.object !== undefined && statusGuide[0] === "TX100")
            //     setInfo(tx100Info(d.object.properties));
            //   if (d.object !== undefined && statusGuide[0] !== "TX100")
            //     setInfo(rhinoInfo(d.object.properties));
            // }}
            autoHighlight={true}
            highlightColor={[160, 160, 180, 10]}
            getLineColor={[46, 49, 49]}
            getFillColor={"#90ee90"
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
          />
        )}
        {showUnauthorizedParkingArea && (
          <GeoJsonLayer
            id="parkingSiteGeom-unauthorized"
            //   data={parkingSiteGeom}
            data={unauthorizedParkingSiteGeom}
            //   visible={query.contextLayersVisibility.districtBoundaries}
            filled={true}
            opacity={0.001}
            pickable={true}
            pickingRadius={5}
            stroked={true}
            //extruded={true}
            pointType={"circle"}
            lineWidthScale={5}
            lineWidthMinPixels={0.1}
            getPointRadius={0.5}
            getLineWidth={0.75}
            //getElevation={500}
            // onClick={(d, event) => {
            //   setActivePopup(true);
            //   setSelectedPoint(d);
            // }}
            // onHover={(d, event) => {
            //   if (d.object !== undefined && statusGuide[0] === "TX100")
            //     setInfo(tx100Info(d.object.properties));
            //   if (d.object !== undefined && statusGuide[0] !== "TX100")
            //     setInfo(rhinoInfo(d.object.properties));
            // }}
            autoHighlight={true}
            highlightColor={[160, 160, 180, 10]}
            getLineColor={[255, 0, 0, 2000]}
            getFillColor={"#90ee90"
              .match(/[0-9a-f]{2}/g)
              .map((x) => parseInt(x, 16))}
          />
        )}
        {showSiteParkingEvents && (
          <ScatterplotLayer
            id="siteEvents"
            // data={statewideData.features}
            // getPosition={(d) => [d.properties.long, d.properties.lat]}
            data={filterSiteEventsGeom()}
            getPosition={(d) => [d.long, d.lat]}
            pickable={true}
            opacity={query.mapOpacity}
            //   stroked={true}
            filled={true}
            //   radiusScale={1}
            //   radiusMinPixels={1000}
            //   radiusMaxPixels={2}
            // lineWidthMinPixels={1}
            // getRadius={3000}
            getRadius={1}
            getLineWiddth={0.0005}
            radiusScale={8}
            radiusMinPixels={1}
            radiusMaxPixels={5}
            lineWidthMinPixels={1}
            //   getFillColor={(d) => {
            //     //console.log("d-check", d);
            //     // if (d.parking_cat === 1) return [0, 255, 0];
            //     // if (d.parking_cat === 2) return [255, 153, 51];
            //     // if (d.parking_cat === 3) return [255, 0, 0];
            //     // return [0, 0, 0];
            //     // return [
            //     //   (d.parking_cat - 1) * 63,
            //     //   255 - (d.parking_cat - 1) * 63,
            //     //   (d.parking_cat - 1) * 63,
            //     // ];
            //     return [
            //       (d.parking_cat - 1) * 63,
            //       255 - (d.parking_cat - 1) * 63,
            //       255 - (d.parking_cat - 1) * 63,
            //     ];
            //   }}
            getFillColor={(d) => {
              let colors = [
                "#7F3C8D",
                "#11A579",
                "#3969AC",
                "#F2B701",
                "#E73F74",
                "#80BA5A",
                "#E68310",
                "#008695",
                "#CF1C90",
                "#f97b72",
                "#4b4b8f",
                "#A5AA99",
              ];

              let colorTemp = colors[d.parking_cat - 1];
              let colorTempRGB = hexToRgb(colorTemp);
              return [colorTempRGB.r, colorTempRGB.g, colorTempRGB.b];
            }}
            getLineColor={(d) => [0, 0, 0]}
          />
        )}
        {stateParkingSitesData !== null && showStateParkingSites && (
          <GeoJsonLayer
            id="state-parking-sites"
            data={stateParkingSitesData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: truckParkingIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {commercialBorderCrossingsData !== null &&
          showCommercialBorderCrossings && (
            <GeoJsonLayer
              id="commercial-border-crossings"
              data={commercialBorderCrossingsData}
              pickable={true}
              wireframe={false}
              pointType={"icon"}
              getIcon={() => ({
                url: commercialBorderCrossingIconSvg,
                width: 24,
                height: 24,
              })}
              getIconSize={1}
              getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
              getIconAngle={0}
              getIconPixelOffset={[0, 0]}
              iconSizeUnits={"pixels"}
              iconSizeScale={15}
            />
          )}
        {railBorderCrossingsData !== null && showRailBorderCrossings && (
          <GeoJsonLayer
            id="rail-border-crossings"
            data={railBorderCrossingsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: railBorderCrossingIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {cargoAirportsData !== null && showCargoAirports && (
          <GeoJsonLayer
            id="cargo-airports"
            data={cargoAirportsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: cargoAirportsIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}
        {portsData !== null && showPorts && (
          <GeoJsonLayer
            id="ports"
            data={portsData}
            pickable={true}
            wireframe={false}
            pointType={"icon"}
            getIcon={() => ({
              url: portsIconSvg,
              width: 24,
              height: 24,
            })}
            getIconSize={1}
            getIconColor={(d) => [Math.sqrt(d.exits), 140, 0]}
            getIconAngle={0}
            getIconPixelOffset={[0, 0]}
            iconSizeUnits={"pixels"}
            iconSizeScale={15}
          />
        )}

        <Grid container spacing={1}>
          <Grid item xs={9}>
            <NavigationControl style={NAV_CONTROL_STYLE} captureScroll={true} />
            <div style={layers_CONTROL_STYLE}>
              <LayerControls
                baseMap={baseMap}
                setBaseMap={setBaseMap}
                dataLayers={dataLayers}
                // showSiteParkingEvents={showSiteParkingEvents}
                // setShowRegionParking={setShowSiteParkingEvents}
                // showAuthorizedParkingArea={showAuthorizedParkingArea}
                // setShowAuthorizedParkingArea={setShowAuthorizedParkingArea}
                // showAuthorizedParkingArea={showAuthorizedParkingArea}
                // setShowAuthorizedParkingArea={setShowAuthorizedParkingArea}
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <SiteMapLegend
              siteSelected={siteSelected}
              dataLayers={dataLayers}
            />
          </Grid>
        </Grid>

        <ScaleControl style={ScaleControl_STYLE} />
      </DeckGL>
    </div>
  );
}

export default DeckglMapSites;
